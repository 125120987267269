import React from 'react';
import { BlockStyleWrapperView } from '@eeacms/volto-block-style/BlockStyleWrapper';

import TitleView from './Title/TitleView';
import installEventDetailsBlock from './Event';
import installFilecardBlock from './FileCard';
import installQuoteBlock from './Quote';
import installLoginBlock from './Login';
import installSocialIconsBlock from './SocialIcons';
import installListingBlock from './Listing';
import installTeaserBlock from './Teaser';
import installSearchInputBlock from './SearchInput';
import installImageBlock from './Image';
import installCustomCardsBlock from './CustomCardsBlock';
import installFooter from './Footer';
// import installGoogleMapBlock from './GoogleMapBlock';
import installLeafletMapBlock from './LeafletMapBlock';
import installSectionDividerBlock from './Section';
import installStoryMapBlock from './StoryMap';

import { compose } from 'redux';

export default (config) => {
  config.blocks.blocksConfig.title.view = (props) => (
    <BlockStyleWrapperView {...props}>
      <TitleView {...props} />
    </BlockStyleWrapperView>
  );

  // config.blocks.blocksConfig.teaser.restricted = false;

  return compose(
    installSectionDividerBlock,
    installTeaserBlock,
    installListingBlock,
    installQuoteBlock,
    installEventDetailsBlock,
    installFilecardBlock,
    installLoginBlock,
    installSocialIconsBlock,
    installSearchInputBlock,
    installImageBlock,
    installCustomCardsBlock,
    // installGoogleMapBlock,
    installFooter,
    installLeafletMapBlock,
    installStoryMapBlock,
  )(config);
};
