import { getAPIResourceWithAuth } from '@plone/volto/helpers';

const HEADERS = ['content-type', 'content-disposition', 'cache-control'];

function icsViewMiddleware(req, res, next) {
  const { errorHandler } = req.app.locals;
  getAPIResourceWithAuth(req)
    .then((resource) => {
      // Just forward the headers that we need
      HEADERS.forEach((header) => {
        if (resource.headers[header]) {
          res.set(header, resource.headers[header]);
        }
      });

      res.send(resource.body);
    }, errorHandler)
    .catch(errorHandler);
}

function install(express) {
  const middleware = express.Router();

  middleware.all(['**/ics_view$', '**/@@ics_view$'], icsViewMiddleware);
  middleware.id = 'ics_view';
  return middleware;
}

export default function voltoInstall(config) {
  if (__SERVER__) {
    const express = require('express');
    config.settings.expressMiddleware = [
      ...(config.settings.expressMiddleware || []),
      install(express),
    ];
  }

  return config;
}
