// import { flattenToAppURL } from '@plone/volto/helpers';
//
// import clearSVG from '@plone/volto/icons/clear.svg';
// import navTreeSVG from '@plone/volto/icons/nav.svg';
import { defaultDisclaimer } from './constants';
import { SocialIcon } from 'volto-trc-project/components/blocks/SocialIcons/schema';

export const GlobalAction = (props) => ({
  title: 'Global Action',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['href', 'title'],
    },
  ],
  properties: {
    title: {
      title: 'Link title',
    },
    href: {
      title: 'Link',
      widget: 'url',
    },
  },
  required: ['title', 'href'],
});

const FooterSchema = (props) => {
  // const { block, data = {}, onChangeBlock, openObjectBrowser } = props;

  return {
    title: 'Footer',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['disclaimer', 'socialIcons', 'globalActions'],
      },
    ],
    properties: {
      disclaimer: {
        title: 'Disclaimer',
        widget: 'textarea',
        default: defaultDisclaimer,
      },
      socialIcons: {
        title: 'Social icons',
        widget: 'object_list',
        schema: SocialIcon(props),
      },
      globalActions: {
        title: 'Global Actions',
        widget: 'object_list',
        schema: GlobalAction(props),
      },
    },
    required: [],
  };
};

export default FooterSchema;
