import imageSVG from '@plone/volto/icons/image.svg';
import LeafletMapBlockView from './LeafletMapBlockView';
import LeafletMapBlockEdit from './LeafletMapBlockEdit';
import MapSchema from './schema';

export default (config) => {
  config.blocks.blocksConfig.leafletMap = {
    id: 'leafletMap',
    title: 'Leaflet Map',
    icon: imageSVG,
    group: 'media',
    view: LeafletMapBlockView,
    edit: LeafletMapBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    schema: MapSchema,
    security: {
      addPermission: [],
      view: [],
    },
  };

  return config;
};
