/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import { useSelector } from 'react-redux';
import React from 'react';
import { SlotRenderer } from '@eeacms/volto-slots/components';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = () => {
  const content = useSelector((state) => state.content?.data);
  return <SlotRenderer name="footer" metadata={content} />;
};

export default Footer;
