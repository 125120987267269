import loadable from '@loadable/component';

import BodyClass from '@plone/volto/helpers/BodyClass/BodyClass';
import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';
import WorkflowState from './components/WorkflowState/WorkflowState';

import { isSlotAvailable } from '@eeacms/volto-slots/components/helpers/Slots';

import installMiddleware from './middleware';
import installViews from './components/views';
import installBlocks from './components/blocks';
import StoryView from './components/views/Story/StoryView';
import ResourceView from './components/views/Resource/ResourceView';
import installSlateLink from './components/slate/Link';
import installCallout from '@plone/volto-slate/editor/plugins/Callout';
import DefaultView from './components/views/DefaultView/DefaultView';

import ContactListWidget from './components/Widgets/ContactListWidget';
import EmailWidget from './components/Widgets/EmailWidget';

import { applyStyleWrapperToBlock } from '@eeacms/volto-block-style';
import { compose } from 'redux';
import { rawdata } from './redux';
// import eeaColors from '@eeacms/volto-block-style/StyleWrapper/EEAColorscheme';
import { GoogleAnalyticsPing } from './ga';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  config.addonReducers.rawdata = rawdata;

  config.settings.loadables = {
    ...config.settings.loadables,
    dateFns: loadable.lib(() => import('date-fns')),
    reactLeaflet: loadable.lib(() => import('react-leaflet'), { ssr: false }),
    leaflet: loadable.lib(() => import('leaflet'), { ssr: false }),
  };

  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes.filter((p) => p !== '/search'),
    /.*\/ics_view$/,
    /.*\/design-system$/,
  ];

  config.views.contentTypesViews.story = StoryView;
  config.views.contentTypesViews.resource = ResourceView;

  Object.keys(config.views.errorViews).forEach((v) => {
    const View = config.views.errorViews[v];
    config.views.errorViews[v] = (props) => (
      <>
        <BodyClass className={`view-notfound view-error${v}`} />
        <View {...props} />
      </>
    );
  });

  // config.views.layoutViews.document_view = DefaultView;
  config.views.defaultView = DefaultView;

  config.widgets.id.facility_type = TokenWidget;
  config.widgets.id.us_state = TokenWidget;
  config.widgets.id.service_type = TokenWidget;
  config.widgets.widget.email = EmailWidget;

  config.widgets.widget.contact_list = ContactListWidget;

  config.settings.pluggableStyles = [
    ...(config.settings.pluggableStyles || []),
    {
      id: 'moveToTop',
      title: 'Above content',
      cssClass: 'move-to-top',
    },
    {
      id: 'newsRightColumn',
      title: 'News Right Column',
      cssClass: 'news-right-column',
    },
    {
      id: 'frontPageCallout',
      title: 'Front Page Callout',
      cssClass: 'front-page-callout',
    },
  ];

  const fullLayoutTypes = ['story'];

  config.slots.asideRightSlot.available = (props) => {
    return fullLayoutTypes.indexOf(props.content['@type']) > -1
      ? false
      : isSlotAvailable(props);
  };

  config.slots.footer = {
    title: 'Footer',
    manage: true,

    items: [],
  };
  config.slots.topBar = {
    title: 'Top navbar',
    manage: true,

    items: [],
  };

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: WorkflowState,
    },
  ];

  const finalConfig = (config) => {
    const mostUsed = ['sideQuote', 'listing', 'image'];
    const groups = {
      text: [],
      media: [
        'image',
        'leadimage',
        'video',
        'maps',
        'imagecards',
        'imagesGrid',
        'pdf_viewer',
      ],
      common: ['slateFootnotes', 'slateTable'],
      trc: [
        'sideQuote',
        'eventDetails',
        'fileCard',
        // 'searchBlock',
        'form',
        'metadata',
        'metadataSection',
        'socialIcons',
      ],
      layout: [
        'searchInput',
        'columnsBlock',
        '__grid',
        'teaserGrid',
        'tabs_block',
        'loginBlock',
        'contextNavigation',
        'group',
        'splitter',
      ],
      site: [],
      special: [],
    };
    const restricted = ['title', 'text', 'table', 'detachedSlate'];

    config.blocks.blocksConfig.searchBlock = {
      ...config.blocks.blocksConfig.search,
      id: 'searchBlock',
      restricted: true,
    };

    Object.keys(config.blocks.blocksConfig).forEach((name) => {
      const block = config.blocks.blocksConfig[name];

      block.mostUsed = mostUsed.indexOf(name) !== -1;
      block.restricted = restricted.indexOf(name) !== -1;
    });

    Object.keys(groups).forEach((group) => {
      Object.keys(config.blocks.blocksConfig).forEach((name) => {
        const block = config.blocks.blocksConfig[name];
        if (groups[group].indexOf(name) > -1) block.group = group;
      });
    });

    config.blocks.groupBlocksOrder = [
      // ...config.blocks.groupBlocksOrder,
      { id: 'common', title: 'Common' },
      { id: 'media', title: 'Media' },
      { id: 'trc', title: 'GPTrac' },
      { id: 'layout', title: 'Layout' },
      { id: 'special', title: 'Special' },
    ];

    // console.log(config.blocks.blocksConfig);
    return config;
  };

  // fix wrapper for some blocks;
  config.blocks.blocksConfig.splitter = applyStyleWrapperToBlock(
    config.blocks.blocksConfig.splitter,
  );
  config.blocks.blocksConfig.imagecards.defaultRendererName = 'carousel';

  config.settings.available_colors.unshift('#7a0019'); // minesota color

  config.settings.appExtras = [
    ...(config.settings.appExtras || []),
    {
      match: '',
      component: GoogleAnalyticsPing,
    },
  ];

  return compose(
    finalConfig,
    installViews,
    installBlocks,
    installMiddleware,
    installCallout,
    installSlateLink,
  )(config);
}

// import SelectWidget from './components/Widgets/SelectWidget';
// import EventView from './components/views/Event/EventView';
// import SingleTokenWidget from './components/Widgets/SingleTokenWidget';
// config.settings.defaultPageSize = 100000;
// config.settings.defaultPageSize = 10;
// config.widgets.widget.vocab_select = SelectWidget;
// config.widgets.id.facility_type = SingleTokenWidget;
// config.widgets.id.us_state = SingleTokenWidget;
// config.widgets.id.service_type = SingleTokenWidget;
// { id: 'mostUsed', title: 'Most used' },
// { id: 'text', title: 'Text' },
// { id: 'site', title: 'Site' },

// ["title", "description", "text", "image", "leadimage", "listing", "video",
//   "toc", "hero", "maps", "html", "table", "contextNavigation",
//   "columnsBlock", "slate", "detachedSlate", "slateTable", "slateFootnotes",
//   "group", "metadata", "metadataSection", "__grid", "teaserGrid",
//   "imagesGrid", "teaser", "imagecards", "tabs_block", "form", "loginBlock",
//   "searchBlock", "fileCard", "eventDetails", "sideQuote"]
//
// G-XX5EK23TGC
// <!-- Global site tag (gtag.js) - Google Analytics --> <script async src="https://www.googletagmanager.com/gtag/js?id=G-XX5EK23TGC"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-XX5EK23TGC'); </script>
