import React from 'react';
import { Segment } from 'semantic-ui-react';
import { compose } from 'redux';
import { useFileData } from './withFileData';
import { withBlockExtensions } from '@plone/volto/helpers';

import StoryMap from './StoryMap';

const StoryMapView = ({ data = {}, file_data, ...rest }) => {
  const file_path = data?.file_path?.[0]?.['@id'];
  const fd = useFileData(file_path, file_data);
  const { loading, loaded } = fd;
  const localFileData = fd.file_data;

  return loading ? (
    <Segment loading>Loading data</Segment>
  ) : localFileData ? (
    <StoryMap data={{ ...data, file_data: localFileData, loading, loaded }} />
  ) : (
    ''
  );
};

export default compose(withBlockExtensions)(StoryMapView);
