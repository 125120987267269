import React from 'react';
import { compose } from 'redux';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import pinSVG from '@plone/volto/icons/navigation.svg';

import withFileData from './withFileData';
import withBlockDataSource from './withBlockDataSource';

import { StoryMapSchema } from './schema';
import StoryMapView from './StoryMapView';
import config from '@plone/volto/registry';

import './editor.less';

const tweakSchema = (schema, data, file_data) => {
  const { columns, latitude, longitude } = schema.properties;
  const ColumnsSchema = columns.schema;

  const choices = (file_data?.meta?.fields || []).sort().map((n) => [n, n]);
  latitude.choices = choices;
  longitude.choices = choices;
  ColumnsSchema.properties.column.choices = choices;

  const { valueRenderers = [] } = config.blocks.blocksConfig.storymap;
  const renderers = valueRenderers.map(({ id, title }) => [id, title]);

  columns.schemaExtender = (schema, data) => {
    schema.properties.renderer.choices = renderers;
    const extension = data.renderer
      ? (valueRenderers.find((f) => f.id === data.renderer) || {})
          .schemaExtender
      : null;
    return extension ? extension(schema, data) : schema;
  };

  return schema;
};

const StoryMapEdit = (props) => {
  const { selected, onChangeBlock, block, data, file_data, intl } = props;

  const blockSchema = React.useMemo(() => StoryMapSchema({ intl }), [intl]);
  const schema = tweakSchema(blockSchema, data, file_data);

  return (
    <>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
      <StoryMapView {...props} />
    </>
  );
};

const getFilePath = ({ data: { file_path } }) => file_path?.[0]?.['@id'];

export default compose(
  withFileData(getFilePath),
  withBlockDataSource({
    getFilePath,
    icon: pinSVG,
    title: 'Sites location file',
  }),
)(StoryMapEdit);
