import React from 'react';
import { compose } from 'redux';
import loadable from '@loadable/component';
import { Input, Checkbox, Segment, Dimmer, Button } from 'semantic-ui-react'; // Radio, Loader,

import { withBlockExtensions } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import useData from './useData';
import InfoWindow from './InfoWindow';
import withMarkerIcons from './withMarkerIcons';
import useDebouncedValue from './useDebouncedValue';

import './leaflet.less';

const MarkerClusterGroup = loadable(() =>
  import('react-leaflet-markercluster'),
);

const ICONS = {
  CAH: 'hospital',
  RHC: 'hospital',
  FQHC: 'fq',
  'FQHC-LA': 'fq',
  MHC: 'mh',
  SUD: 'su',
};

const prepareData = (rows) =>
  rows?.map((row) => ({
    sitename: row[2],
    healthsystem: row[0],
    site: row[1],
    lat: parseFloat(row[10]),
    lng: parseFloat(row[11]),
    type: row[3],
    county: row[4],
    city: row[6],
    state: row[7],
    search: [row[2], row[1], row[4], row[6]].join(' ').toLowerCase(),
  })) || [];

const mapOptions = {
  center: {
    lat: 44.986656,
    lng: -93.258133,
  },
  zoom: 6,
  minZoom: 4,
  maxZoom: 17,
};

const stateZoomThreshold = 6;

const LeafletMapBlockView = (props) => {
  const { reactLeaflet, data, icons } = props; // leaflet: L
  const {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Circle,
    CircleMarker,
    Tooltip,
  } = reactLeaflet;

  const [loaded, setLoaded] = React.useState(false);
  const { dataSourceUrl } = data; //apiKey,
  const nodeId = `leaflet-map-${props.block || props.id}`;

  const { data: sourceData } = useData(dataSourceUrl);

  const [isClient, setIsClient] = React.useState(false);
  const [map, setMap] = React.useState();
  const [zoom, setZoom] = React.useState(mapOptions.zoom);
  // const [bounds, setBounds] = React.useState();

  React.useEffect(() => setIsClient(true), []);
  const [searchText, setSearchText] = useDebouncedValue(null);

  const mapData = React.useMemo(
    () =>
      sourceData
        ? prepareData(sourceData.values)
        : // .map((l) => ({
          //       // .slice(0, 500)
          //       ...l,
          //       latlng: L.latLng(l.lat, l.lng),
          //     }))
          [], // .slice(0, 1000)
    [sourceData], // , L
  );

  console.log('activeData', Circle, mapData); // , mapData

  const facilityTypes = React.useMemo(() => {
    if (!mapData) return;
    const types = [];
    mapData.forEach((location) => {
      if (types.indexOf(location.type) === -1) {
        types.push(location.type);
      }
    });
    return types; // .map((type) => [type, type]);
  }, [mapData]);

  const [selectedType, setSelectedType] = React.useState([]);

  React.useEffect(() => {
    if (facilityTypes.length) setSelectedType(facilityTypes);
  }, [facilityTypes]);

  const showMarkers = zoom > stateZoomThreshold;

  const activeData = React.useMemo(
    () =>
      (selectedType
        ? (mapData || []).filter(
            (l) =>
              selectedType.length === 0 ? true : selectedType.includes(l.type), // && bounds.contains(l.latlng)
          )
        : mapData || []
      ).filter((l) =>
        searchText ? l.search.indexOf(searchText.toLowerCase()) > -1 : true,
      ), // .filter((l) => bounds.contains(l.latlng))
    [mapData, selectedType, searchText], // , bounds
  );

  React.useEffect(() => {
    MarkerClusterGroup.load().then(() => setLoaded(true));
  }, []);

  const stateData = React.useMemo(() => {
    const stat = {};
    activeData.forEach((p) => {
      if (!stat[p.state]) {
        stat[p.state] = {
          lats: [],
          longs: [],
          count: 0,
        };
      }
      const s = stat[p.state];
      s.count += 1;
      s.lats.push(p.lat);
      s.longs.push(p.lng);
    });
    Object.values(stat).forEach((s) => {
      s.lat = s.lats.reduce((prev, curr) => prev + curr, 0) / s.lats.length;
      s.lng = s.longs.reduce((prev, curr) => prev + curr, 0) / s.longs.length;
    });
    return stat;
  }, [activeData]);

  console.log('stateData', stateData);
  const showDimmer = !(loaded && mapData?.length);

  return (
    <div id={nodeId} className="leaflet-map-block">
      <div>
        {/* <Loader active={!mapData?.length} inline /> */}
        <Segment basic>
          <strong>Health provider type</strong>
          <br />
          <Input
            loading={!mapData?.length}
            placeholder="Search..."
            onChange={(e, { value }) => {
              console.log('cab', value);
              setSearchText(value);
            }}
            icon={'search'}
          />{' '}
          Showing {activeData?.length} results.
        </Segment>
        <Segment basic className="filter">
          {(facilityTypes || []).map((facType) => (
            <Checkbox
              key={facType}
              label={facType}
              checked={selectedType.includes(facType)}
              onChange={(id, { checked }) => {
                setSelectedType([
                  ...(!checked && selectedType.length === facilityTypes?.length
                    ? []
                    : selectedType.filter((f) => f !== facType)),
                  ...(checked || selectedType.length === facilityTypes?.length
                    ? [facType]
                    : []),
                ]);
                map &&
                  map.setView(
                    [mapOptions.center.lat, mapOptions.center.lng],
                    mapOptions.zoom,
                  );
              }}
            />
          ))}
          {selectedType.length !== facilityTypes?.length && (
            <Button
              compact
              simple
              size="mini"
              onClick={() => {
                setSelectedType(facilityTypes);
              }}
            >
              Reset
            </Button>
          )}
        </Segment>
      </div>
      <Dimmer.Dimmable dimmed={showDimmer}>
        <div style={{ height: data.height || '400px' }}>
          {isClient && loaded && (
            <MapContainer
              className="markercluster-map"
              style={{ height: '100%' }}
              center={[mapOptions.center.lat, mapOptions.center.lng]}
              zoom={mapOptions.zoom}
              minZoom={mapOptions.minZoom}
              maxZoom={mapOptions.maxZoom}
              whenCreated={(map) => {
                setMap(map);
                map.on('zoom', (e) => {
                  setZoom(e.target._zoom);
                });
              }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />

              {showMarkers ? (
                <MarkerClusterGroup>
                  {activeData?.map((m, i) => {
                    const icon = icons[ICONS[m.type] || 'hospital'];
                    return (
                      <Marker key={i} position={[m.lat, m.lng]} icon={icon}>
                        <Popup>
                          <InfoWindow location={m} />
                        </Popup>
                      </Marker>
                    );
                  })}
                </MarkerClusterGroup>
              ) : (
                Object.keys(stateData).map((name) => {
                  const state = stateData[name];
                  return (
                    <CircleMarker
                      key={name}
                      radius={20}
                      center={[state.lat, state.lng]}
                      pathOptions={{ fillColor: 'green', fillOpacity: 1 }}
                    >
                      <Tooltip
                        permanent={true}
                        className="text"
                        direction="center"
                      >
                        {name}
                        <br />
                        {state.count}
                      </Tooltip>
                    </CircleMarker>
                  );
                })
              )}
            </MapContainer>
          )}
        </div>
        <Dimmer active={showDimmer} />
      </Dimmer.Dimmable>
    </div>
  );
};

export default compose(
  injectLazyLibs(['reactLeaflet', 'leaflet']),
  withMarkerIcons,
  withBlockExtensions,
)(LeafletMapBlockView);

// {/* <Radio */}
// {/*   label="Show all" */}
// {/*   checked={!selectedType} */}
// {/*   onChange={(id, data) => { */}
// {/*     setSelectedType([]); */}
// {/*     map && */}
// {/*       map.setView( */}
// {/*         [mapOptions.center.lat, mapOptions.center.lng], */}
// {/*         mapOptions.zoom, */}
// {/*       ); */}
// {/*   }} */}
// {/* /> */}

// console.log('created', map, map.getBounds());
// setBounds(map.getBounds());
// map.on('moveend', (r) => {
//   setBounds(map.getBounds());
// });
// map.on('zoom', (r) => {
//   setBounds(map.getBounds());
// });
// import { Icon } from '@plone/volto/components';
// import zoomSVG from '@plone/volto/icons/zoom.svg';
// import RadioWidget from './RadioWidget';
