import React from 'react';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const ContactSchema = {
  title: 'Contact',
  properties: {
    name_credentials: {
      title: 'Name and credentials',
    },
    contact_title: {
      title: 'Personal title',
    },
    relevance_to_story: {
      title: 'Relevance to story',
    },
    email: {
      title: 'Email',
    },
    phone: {
      title: 'Phone',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Contact',
      fields: [
        'name_credentials',
        'contact_title',
        'relevance_to_story',
        'email',
        'phone',
      ],
    },
  ],
  required: [],
};

const ContactListWidget = (props) => {
  return (
    <ObjectListWidget
      schema={ContactSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default ContactListWidget;
