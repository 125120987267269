/**
 * ResourceView view component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';

import { flattenToAppURL, flattenHTMLToAppURL } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { hasBlocksData } from '@plone/volto/helpers';
import PdfBlockView from '@eeacms/volto-pdf-block/PDFViewer/BlockView';
import { UniversalLink } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';
import SimplifiedBreadcrumb from 'volto-trc-project/components/blocks/Title/SimplifiedBreadcrumb';

const ResourceDetailsView = (props) => {
  const { content } = props;
  return (
    <>
      {content.title && (
        <h1 className="documentFirstHeading">
          {content.title}
          {content.subtitle && ` - ${content.subtitle}`}
        </h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="documentImage"
          alt={content.title}
          title={content.title}
          src={
            content.image['content-type'] === 'image/svg+xml'
              ? flattenToAppURL(content.image.download)
              : flattenToAppURL(content.image.scales.mini.download)
          }
          floated="right"
        />
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: flattenHTMLToAppURL(content.text.data),
          }}
        />
      )}
    </>
  );
};

const ResourceView = (props) => {
  const { content } = props;
  const { file, remoteUrl } = content;
  const url = file?.download
    ? file.download.replace('@@download/file', '')
    : null;
  return (
    <Container className="view-wrapper resource-view">
      <SimplifiedBreadcrumb />
      {hasBlocksData(content) ? (
        <>
          <RenderBlocks {...props} />
          {url ? <PdfBlockView data={{ url }} /> : null}
        </>
      ) : (
        <>
          <ResourceDetailsView {...props} />
          {url ? <PdfBlockView data={{ url }} /> : null}
        </>
      )}

      {remoteUrl ? (
        <div className="extra-footer">
          <Button
            compact
            secondary
            className="news"
            as={UniversalLink}
            href={flattenToAppURL(remoteUrl)}
          >
            Read more
          </Button>
        </div>
      ) : null}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ResourceView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default ResourceView;
