import React from 'react';
import Anontools, { Anontools as UnconnectedAnontools } from './AnonTools';
import { Button } from 'semantic-ui-react';

const LoginView = (props) => {
  const { mode, data = {} } = props;
  const Wrapper = data.style === 'button' ? Button : React.Fragment;
  return (
    <div className="login-button">
      {mode === 'edit' ? (
        <Wrapper>
          <UnconnectedAnontools
            content={props.content}
            as={data.style || 'a'}
          />
        </Wrapper>
      ) : (
        <Wrapper>
          <Anontools {...props} />
        </Wrapper>
      )}
    </div>
  );
};

export default LoginView;
