import downloadSVG from '@plone/volto/icons/download.svg';
import LoginBlockView from './LoginBlockView';
import LoginBlockEdit from './LoginBlockEdit';

export default (config) => {
  config.blocks.blocksConfig.loginBlock = {
    id: 'loginBlock',
    title: 'Login link',
    icon: downloadSVG,
    group: 'site',
    view: LoginBlockView,
    edit: LoginBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
