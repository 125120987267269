import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { SidebarPortal, Icon } from '@plone/volto/components';
import UploadWidget from './UploadWidget';

const withBlockDataSource = (opts) => (WrappedComponent) => {
  const { icon, title, getFilePath } = opts;

  function WrappedBlockDataSource(props) {
    const {
      data,
      selected,
      onChangeBlock,
      onSelectBlock,
      block,
      pathname,
      openObjectBrowser,
    } = props;
    const file_path = getFilePath(props);

    return (
      <div className={`${data['@type']}-edit`}>
        {!file_path ? (
          <>
            <div className="no-value">
              <Form>
                <Icon name={icon} size="100px" color="#b8c6c8" />
                <UploadWidget
                  block={block}
                  id="file_path"
                  description="Select or upload a CSV file"
                  placeholder="CSV locations file"
                  onFocus={() => onSelectBlock(block)}
                  value={file_path}
                  pathname={pathname}
                  openObjectBrowser={openObjectBrowser}
                  onChange={(id, value) => {
                    onChangeBlock(block, {
                      ...data,
                      file_path: value,
                      data: {
                        file_path: value,
                      },
                    });
                  }}
                />
              </Form>
            </div>

            <SidebarPortal selected={selected}>
              <Segment.Group raised>
                <header className="header pulled">
                  <h2>{title}</h2>
                </header>
                <Segment className="sidebar-metadata-container" secondary>
                  No file selected
                  <Icon name={icon} size="100px" color="#b8c6c8" />
                </Segment>
              </Segment.Group>
            </SidebarPortal>
            <WrappedComponent {...props} />
          </>
        ) : (
          <WrappedComponent {...props} />
        )}
      </div>
    );
  }
  return WrappedBlockDataSource;
};

export default withBlockDataSource;
