/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import DesignSystem from 'volto-trc-project/components/DesignSystem';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/design-system',
        component: DesignSystem,
      },
      // Add your routes here
      ...(config.addonRoutes || []),
      ...defaultRoutes.filter(({ path }) => path !== '/search'),
    ],
  },
];

export default routes;
