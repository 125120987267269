import React from 'react';
import { Message } from 'semantic-ui-react';
import defaultIcon from 'volto-trc-project/icons/link.svg';
import facebookIcon from 'volto-trc-project/icons/facebook.svg';
import twitterIcon from 'volto-trc-project/icons/twitter.svg';
import twitchIcon from 'volto-trc-project/icons/twitch.svg';
import linkedinIcon from 'volto-trc-project/icons/linkedin.svg';
import { Icon } from '@plone/volto/components';

import './styles.css';

const SocialLink = ({ href = '', title = '' }) => {
  const icon =
    href.indexOf('facebook') > -1
      ? facebookIcon
      : href.indexOf('twitter') > -1
      ? twitterIcon
      : href.indexOf('linkedin') > -1
      ? linkedinIcon
      : href.indexOf('twitch') > -1
      ? twitchIcon
      : defaultIcon;

  return (
    <div className="socialLink">
      <a href={href} title={title}>
        <Icon name={icon} size="16px" />
      </a>
    </div>
  );
};

const DefaultSocialIcons = (props) => (
  <>
    <a href="https://www.facebook.com/greatplains.trac/" title="Facebook">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon"
      >
        <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
      </svg>
    </a>
    <a href="https://twitter.com/gpTRAC" title="Twitter">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon"
      >
        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
      </svg>
    </a>
    <a href="https://www.linkedin.com/company/gptrac/" title="LinkedIN">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon"
      >
        <path
          fill="#ffffff"
          d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
              C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
              M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
              c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
              s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"
        ></path>
      </svg>
    </a>
  </>
);

export default (props) => {
  const { data = {} } = props;
  return data?.links?.length ? (
    data.links.map((l, i) => (
      <SocialLink key={`${l.href}-${i}`} href={l.href} title={l.title} />
    ))
  ) : (
    <DefaultSocialIcons />
  );
};
