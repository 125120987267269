import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import csv from 'papaparse';
import { getRawContent } from 'volto-trc-project/redux';

export const useFileData = (url, file_data) => {
  const path = url ? `${url}/@@download` : null;

  const dispatch = useDispatch();
  const request = useSelector((state) => state.rawdata?.[path]);

  const content = request?.data;

  React.useEffect(() => {
    if (!file_data && path && !request?.loading && !request?.loaded && !content)
      dispatch(getRawContent(path));
  }, [file_data, dispatch, path, content, request?.loaded, request?.loading]);

  const local_file_data = React.useMemo(() => {
    if (content) {
      const res = csv.parse(content, { header: true });
      return res;
    }
  }, [content]);

  return {
    file_data: file_data || local_file_data,
    loading: request?.loading,
    loaded: request?.loaded,
  };
};

const withFileData = (getFilePath) => (WrappedComponent) => {
  return (props) => {
    const url = getFilePath(props);
    const { file_data, loading, loaded } = useFileData(url); // file_path?.['@id']

    return (
      <WrappedComponent
        file_data={file_data}
        loading={loading}
        loaded={loaded}
        {...props}
      />
    );
  };
};

export default withFileData;
