import React from 'react';
import { getContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseUrl, flattenToAppURL } from '@plone/volto/helpers';
import FileCard from './FileCard';
import { Message } from 'semantic-ui-react';

export default (props) => {
  const { data: { href } = {} } = props;
  const dispatch = useDispatch();
  const url = href ? flattenToAppURL(getBaseUrl(href)) : '';

  React.useEffect(() => {
    if (url) {
      dispatch(getContent(url, null, url));
    }
  }, [url, dispatch]);

  const item = useSelector((state) => {
    return state.content.subrequests?.[url]?.data;
  });

  return item ? (
    <FileCard item={item} />
  ) : (
    <Message>Empty filecard block</Message>
  );
};
