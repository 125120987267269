import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { default as ReactGA4 } from 'react-ga4';
import config from '@plone/volto/registry';

const GA4_trackingCode = 'G-XX5EK23TGC';

const cookieExpires =
  config.settings?.['volto-google-analytics']?.cookieExpires ??
  6 * 30 * 24 * 60 * 60; // in seconds. Default: 6 month

if (__CLIENT__) {
  if (GA4_trackingCode) {
    ReactGA4.initialize([
      {
        testMode: __DEVELOPMENT__ ?? false,
        trackingId: GA4_trackingCode,
        gaOptions: {
          anonymizeIp: true,
          cookieExpires: cookieExpires,
        },
      },
    ]);
  }
}

const getFilename = (fname) => {
  const bits = fname
    .replace('@@download/file', '')
    .split('/')
    .filter((s) => !!s);
  return bits.length ? bits[bits.length - 1] : fname;
};

const useGoogleAnalytics = () => {
  let location = useLocation();

  useEffect(() => {
    if (GA4_trackingCode) {
      ReactGA4.send({ hitType: 'pageview', page: location.pathname });
    }

    const handler = (e) => {
      const { url, file_name } = e.detail;

      ReactGA4._gtag('event', 'file_download', {
        page_location: location.pathname,
        link_url: url,
        file_name: getFilename(file_name || url),
      });
    };
    document.body.addEventListener('pdfDownloadEvent', handler);
    return () => document.body.removeEventListener('pdfDownloadEvent', handler);
  }, [location]);
};

export const GoogleAnalyticsPing = (props) => {
  useGoogleAnalytics();
  return null;
};
