import React from 'react';

const useDebouncedValue = (defaultValue, options = {}) => {
  const { timeout = 1000 } = options;
  const [state, setState] = React.useState(defaultValue);

  const timerRef = React.useRef();

  const setValue = React.useCallback(
    (value) => {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setState(value);
        console.log('triggered', value);
      }, timeout);
    },
    [timeout],
  );

  return [state, setValue];
};
export default useDebouncedValue;
