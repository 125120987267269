import listBulletSVG from '@plone/volto/icons/list-bullet.svg';
import EventDetailsBlockView from './EventDetailsBlockView';
import EventDetailsBlockEdit from './EventDetailsBlockEdit';

export default (config) => {
  config.blocks.blocksConfig.eventDetails = {
    id: 'eventDetails',
    title: 'Event details',
    icon: listBulletSVG,
    group: 'common',
    view: EventDetailsBlockView,
    edit: EventDetailsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
