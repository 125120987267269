export default ({ data = {} }) => ({
  title: 'Search input',
  fieldsets: [{ id: 'default', title: 'Default', fields: ['submitPath'] }],
  properties: {
    submitPath: {
      title: 'Path to search page',
      placeholder: '/search',
    },
  },
  required: [],
});
