/**
 * EventView view component.
 * @module components/theme/View/EventView
 */

import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Container, Image, Segment, Header, List } from 'semantic-ui-react';
import { hasBlocksData, getBlocks } from '@plone/volto/helpers';
import DownloadList from 'volto-trc-project/components/DownloadList/DownloadList';
import { getBaseUrl } from '@plone/volto/helpers';
import { v4 as uuid } from 'uuid';

import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';

const messages = defineMessages({
  what: {
    id: 'event_what',
    defaultMessage: 'What',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Where',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact Name',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Contact Phone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Website',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
});

const addEventDetailsBlock = (content) => {
  const hasBlock = Object.values(content.blocks).find(
    (b) => b?.['@type'] === 'eventDetails',
  );

  // move the event details block to the top
  if (hasBlock) {
    const eventDetailsBlocks = getBlocks(content)
      .filter(([id, block]) => block?.['@type'] === 'eventDetails')
      .map(([id]) => id);
    content.blocks_layout.items = content.blocks_layout.items.filter(
      (id) => eventDetailsBlocks.indexOf(id) === -1,
    );
  }

  const uid = uuid();
  content.blocks[uid] = { '@type': 'eventDetails' };
  content.blocks_layout.items = [
    content.blocks_layout.items[0],
    uid,
    ...content.blocks_layout.items.slice(1),
  ];

  return content;
};

const addDescriptionBlock = (content) => {
  const hasBlock = Object.values(content.blocks).find(
    (b) => b?.['@type'] === 'description',
  );

  if (!hasBlock) {
    const uid = uuid();
    content.blocks[uid] = { '@type': 'description' };
    content.blocks_layout.items = [
      content.blocks_layout.items[0],
      uid,
      ...content.blocks_layout.items.slice(1),
    ];
  }

  return content;
};

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = (props) => {
  const { intl, content } = props;
  return (
    <Container className="view-wrapper event-view">
      {hasBlocksData(content) ? (
        <RenderBlocks
          {...props}
          content={addEventDetailsBlock(addDescriptionBlock(content))}
        />
      ) : (
        <>
          {content.title && (
            <h1 className="documentFirstHeading">{content.title}</h1>
          )}
          <Segment floated="right" className="details">
            {content.subjects.length > 0 && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.what)}
                </Header>
                <List items={content.subjects} />
              </>
            )}
            <Header dividing sub>
              {intl.formatMessage(messages.when)}
            </Header>
            <When
              start={content.start}
              end={content.end}
              whole_day={content.whole_day}
              open_end={content.open_end}
            />
            {content.recurrence && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.allDates)}
                </Header>
                <Recurrence
                  recurrence={content.recurrence}
                  start={content.start}
                />
              </>
            )}
            {content.location && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.where)}
                </Header>
                <p>{content.location}</p>
              </>
            )}
            {content.contact_name && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.contactName)}
                </Header>
                <p>
                  {content.contact_email ? (
                    <a href={`mailto:${content.contact_email}`}>
                      {content.contact_name}
                    </a>
                  ) : (
                    content.contact_name
                  )}
                </p>
              </>
            )}
            {content.contact_phone && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.contactPhone)}
                </Header>
                <p>{content.contact_phone}</p>
              </>
            )}
            {content.attendees.length > 0 && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.attendees)}
                </Header>
                <List items={content.attendees} />
              </>
            )}

            {content.event_url && (
              <>
                <Header dividing sub>
                  {intl.formatMessage(messages.website)}
                </Header>
                <p>
                  <a
                    href={content.event_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage(messages.visitWebsite)}
                  </a>
                </p>
              </>
            )}

            <>
              <Header dividing sub>
                Add event to calendar
              </Header>
              <p>
                <a
                  href={`${content['@id']}/@@ics_view`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iCal
                </a>
              </p>
            </>
          </Segment>

          {content.description && (
            <p className="documentDescription">{content.description}</p>
          )}
          {content.image && (
            <Image
              className="document-image"
              src={content.image.scales.thumb.download}
              floated="right"
            />
          )}
          {content.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: flattenHTMLToAppURL(content.text.data),
              }}
            />
          )}
        </>
      )}
      <DownloadList {...props} block={getBaseUrl(props.location.pathname)} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    attendees: PropTypes.arrayOf(PropTypes.string).isRequired,
    contact_email: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
    end: PropTypes.string.isRequired,
    event_url: PropTypes.string,
    location: PropTypes.string,
    open_end: PropTypes.bool,
    recurrence: PropTypes.any,
    start: PropTypes.string.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    whole_day: PropTypes.bool,
  }).isRequired,
};

export default injectIntl(EventView);
