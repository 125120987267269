import React from 'react';

const InfoWindow = (props) => {
  const { location } = props;
  const metadata = {
    type: 'Facility type',
    city: 'City',
    county: 'County',
    state: 'State',
  };
  const links = ['healthsystem', 'site'];
  return (
    <div>
      {links.map((key, i) =>
        location[key] ? (
          <p key={i}>
            <strong>
              {!location.url ? (
                location[key]
              ) : (
                <a href={location.url}>{location[key]}</a>
              )}
            </strong>
          </p>
        ) : (
          ''
        ),
      )}
      {Object.entries(metadata).map(([key, label], i) =>
        location[key] ? (
          <p key={i}>
            <strong>{label}: </strong>
            {location[key]}
          </p>
        ) : (
          ''
        ),
      )}
    </div>
  );
};

export default InfoWindow;
