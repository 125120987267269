import zoomSVG from '@plone/volto/icons/zoom.svg';
import SectionView from './SectionView';
import SectionEdit from './SectionEdit';

export default (config) => {
  config.blocks.blocksConfig.sectionDivider = {
    id: 'sectionDivider',
    title: 'Section Divider',
    icon: zoomSVG,
    group: 'layout',
    view: SectionView,
    edit: SectionEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
