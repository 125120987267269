const URL =
  'https://sheets.googleapis.com/v4/spreadsheets/1VYdzpd7pLOTXXB8fLvv5n2l9FKF8zmajAs2Q-Xe-NEE/values/Region!A2:Q?key=AIzaSyDNDrFj2XP9djKzPe1BKMYGdwe1c_RhPI0';
// 'https://sheets.googleapis.com/v4/spreadsheets/1VYdzpd7pLOTXXB8fLvv5n2l9FKF8zmajAs2Q-Xe-NEE/values/FQHC!A2:Q?key=AIzaSyDNDrFj2XP9djKzPe1BKMYGdwe1c_RhPI0';

const MapSchema = (props) => {
  return {
    title: 'Google Map',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['dataSourceUrl'], // , 'apiKey'
      },
    ],
    properties: {
      // apiKey: {
      //   title: 'API Key',
      //   description: 'Enter the API Key for Google Maps',
      //   default: 'AIzaSyDNDrFj2XP9djKzPe1BKMYGdwe1c_RhPI0',
      // },
      dataSourceUrl: {
        title: 'Source URL',
        description: 'Enter the URL for the spreadsheet API',
        default: URL,
      },
      height: {
        title: 'Block height',
        description: 'Block height, CSS dimmension',
        default: '80vh',
      },
    },
    required: ['apiKey'],
  };
};

export default MapSchema;
