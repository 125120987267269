export default () => ({
  title: 'Login block',
  fieldsets: [{ id: 'default', title: 'Default', fields: ['style'] }],
  properties: {
    style: {
      title: 'Style',
      choices: [
        ['a', 'Link'],
        ['button', 'Button'],
      ],
    },
  },
  required: [],
});
