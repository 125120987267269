import React from 'react';
import { useLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

// import hBlue from './img/hospital-blue.png';
// import h2xBlue from './img/hospital-2x-blue.png';
// import hGreen from './img/hospital-green.png';
// import h2xGreen from './img/hospital-2x-green.png';
// import hRed from './img/hospital-red.png';
// import h2xRed from './img/hospital-2x-red.png';
// import hYellow from './img/hospital-yellow.png';
// import h2xYellow from './img/hospital-2x-yellow.png';

import fq from './img/fq.png';
import fq2x from './img/fq-2x.png';

import hospital from './img/hospital.png';
import hospital2x from './img/hospital-2x.png';

import mentalHealth from './img/mental-health.png';
import mentalHealth2x from './img/mental-health-2x.png';

import substanceUse from './img/substance-use.png';
import substanceUse2x from './img/substance-use-2x.png';

const defaultSize = {
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
};

const buildIcons = (build) => ({
  hospital: build({
    iconUrl: hospital,
    iconRetinaUrl: hospital2x,
    shadowUrl,
    ...defaultSize,
  }),
  fq: build({
    iconUrl: fq,
    iconRetinaUrl: fq2x,
    shadowUrl,
    ...defaultSize,
  }),
  su: build({
    iconUrl: substanceUse,
    iconRetinaUrl: substanceUse2x,
    shadowUrl,
    ...defaultSize,
  }),
  mh: build({
    iconUrl: mentalHealth,
    iconRetinaUrl: mentalHealth2x,
    shadowUrl,
    ...defaultSize,
  }),
});

const withMarkerIcons = (WrappedComponent) => {
  function Wrapper(props) {
    const [icons, setIcons] = React.useState({});
    const loadedLibs = useLazyLibs(['leaflet']);

    const { leaflet } = loadedLibs || {};

    React.useEffect(() => {
      // fix markers broken by webpack
      if (!leaflet) return;
      const { Icon } = leaflet;

      delete Icon.Default.prototype._getIconUrl;

      Icon.Default.mergeOptions({
        iconRetinaUrl,
        iconUrl,
        shadowUrl,
      });
      // default: Icon.Default,

      setIcons({ default: Icon.Default, ...buildIcons(leaflet.icon) });
    }, [leaflet]);

    return leaflet ? <WrappedComponent {...props} icons={icons} /> : null;
  }
  return Wrapper;
};

export default withMarkerIcons;
