import { defineMessages } from 'react-intl';

export const StoryMapSchema = ({ intl }) => ({
  title: 'Story sites map',

  fieldsets: [
    {
      id: 'default',
      title: intl.formatMessage(messages.defaultFieldset),
      fields: ['file_path', 'latitude', 'longitude'],
    },
    {
      id: 'markers',
      title: 'Marker popups',
      fields: ['columns', 'showDirectionsLink'],
    },
    {
      id: 'style',
      title: 'Style',
      fields: ['height', 'aspectRatio'],
    },
  ],

  properties: {
    height: {
      title: 'Height',
      description: 'Height, as a CSS property',
    },
    aspectRatio: {
      title: 'Aspect ratio',
      description: 'Alternative to height',
      choices: [
        ['1', 'Square'],
        ['16/9', 'Wide landscape (16/9)'],
        ['4/3', 'Landscape (4/3)'],
        ['1/2', 'Portrait'],
      ],
    },
    columns: {
      title: 'Info lines',
      // description: 'Leave empty to show all columns',
      schema: ColumnSchema({ intl }),
      widget: 'object_list',
    },
    showDirectionsLink: {
      title: 'Show direction link?',
      type: 'boolean',
    },
    latitude: {
      title: 'Latitude field',
      choices: [],
    },
    longitude: {
      title: 'Longitude field',
      choices: [],
    },
    file_path: {
      title: intl.formatMessage(messages.dataFile),
      widget: 'object_browser',
      mode: 'link',
    },
  },

  required: ['file_path'],
});

const messages = defineMessages({
  fixed: {
    id: 'Fixed width table cells',
    defaultMessage: 'Fixed width table cells',
  },
  compact: {
    id: 'Make the table compact',
    defaultMessage: 'Make the table compact',
  },
  basic: {
    id: 'Reduce complexity',
    defaultMessage: 'Reduce complexity',
  },
  celled: {
    id: 'Divide each row into separate cells',
    defaultMessage: 'Divide each row into separate cells',
  },
  inverted: {
    id: 'Table color inverted',
    defaultMessage: 'Table color inverted',
  },
  striped: {
    id: 'Stripe alternate rows with color',
    defaultMessage: 'Stripe alternate rows with color',
  },
  styling: {
    id: 'Styling',
    defaultMessage: 'Styling',
  },
  defaultFieldset: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  dataFile: {
    id: 'Data file',
    defaultMessage: 'Data file',
  },
});

const ColumnSchema = () => ({
  title: 'Info line',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['column', 'renderer'],
    },
  ],
  properties: {
    column: {
      title: 'Column',
      choices: [],
    },
    renderer: {
      title: 'Display',
      choices: [],
    },
  },
  required: ['column'],
});
