import groupSVG from '@plone/volto/icons/group.svg';
import SocialIconsEdit from './SocialIconsEdit';
import SocialIconsView from './SocialIconsView';

export default (config) => {
  config.blocks.blocksConfig.socialIcons = {
    id: 'socialIcons',
    title: 'Social Icons',
    icon: groupSVG,
    group: 'trc',
    view: SocialIconsView,
    edit: SocialIconsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
