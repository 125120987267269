import React from 'react';
import withQuerystringResults from './withQuerystringResults';
import { getBaseUrl } from '@plone/volto/helpers';
import { Card } from 'semantic-ui-react';
import FileCard from 'volto-trc-project/components/blocks/FileCard/FileCard';

function DownloadList(props) {
  const { listingItems } = props;
  return listingItems.length ? (
    <div className="download-list">
      <h4>Download files</h4>
      <Card.Group>
        {listingItems.map((item) => (
          <FileCard item={item} key={item['id']} />
        ))}
      </Card.Group>
    </div>
  ) : (
    ''
  );
}

const Wrapped = withQuerystringResults(DownloadList);

export default (props) => (
  <Wrapped
    {...props}
    path={getBaseUrl(props.location.pathname)}
    data={{
      limit: 100000,
      batch_size: 100000,
      block: props.block,
      query: [
        {
          i: 'path',
          o: 'plone.app.querystring.operation.string.relativePath',
          v: '',
        },
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['File'],
        },
      ],
    }}
  />
);
