import React from 'react';
import { Segment, Header, List } from 'semantic-ui-react';
import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';
import { defineMessages, injectIntl } from 'react-intl';
import ErrorBoundary from 'volto-trc-project/components/ErrorBoundary/ErrorBoundary';

const messages = defineMessages({
  what: {
    id: 'event_what',
    defaultMessage: 'What',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Where',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact Name',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Contact Phone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Website',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
});

const EventDetailsView = ({ intl, properties }) => {
  const content = properties;
  return (
    <Segment floated="right">
      <ErrorBoundary errorMessage={() => <div>No data</div>}>
        {content.subjects?.length > 0 && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.what)}
            </Header>
            <List items={content.subjects} />
          </>
        )}
        <Header dividing sub>
          {intl.formatMessage(messages.when)}
        </Header>
        <When
          start={content.start}
          end={content.end}
          whole_day={content.whole_day}
          open_end={content.open_end}
        />
        {content.recurrence && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.allDates)}
            </Header>
            <Recurrence recurrence={content.recurrence} start={content.start} />
          </>
        )}
        {content.location && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.where)}
            </Header>
            <p>{content.location}</p>
          </>
        )}
        {content.contact_name && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.contactName)}
            </Header>
            <p>
              {content.contact_email ? (
                <a href={`mailto:${content.contact_email}`}>
                  {content.contact_name}
                </a>
              ) : (
                content.contact_name
              )}
            </p>
          </>
        )}
        {content.contact_phone && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.contactPhone)}
            </Header>
            <p>{content.contact_phone}</p>
          </>
        )}
        {content.attendees.length > 0 && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.attendees)}
            </Header>
            <List items={content.attendees} />
          </>
        )}
        {content.event_url && (
          <>
            <Header dividing sub>
              {intl.formatMessage(messages.website)}
            </Header>
            <p>
              <a
                href={content.event_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage(messages.visitWebsite)}
              </a>
            </p>
          </>
        )}
        <>
          <Header dividing sub>
            Add event to calendar
          </Header>
          <p>
            <a
              href={`${content['@id']}/@@ics_view`}
              target="_blank"
              rel="noopener noreferrer"
            >
              iCal
            </a>
          </p>
        </>
      </ErrorBoundary>
    </Segment>
  );
};

export default injectIntl(EventDetailsView);
