export const SocialIcon = (props) => ({
  title: 'Social icon',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['href', 'title'],
    },
  ],
  properties: {
    title: {
      title: 'Link title',
    },
    href: {
      title: 'Social link',
      widget: 'url',
    },
  },
  required: ['title', 'href'],
});

const SocialIconsSchema = (props) => {
  return {
    title: 'Social icons',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['links'],
      },
    ],
    properties: {
      links: {
        title: 'Social icons',
        widget: 'object_list',
        schema: SocialIcon(),
      },
    },
    required: [],
  };
};

export default SocialIconsSchema;

// href: {
//   title: 'Source',
//   icon: data.href ? clearSVG : navTreeSVG,
//   iconAction: data.href
//     ? () => {
//         onChangeBlock(block, {
//           ...data,
//           href: '',
//           title: '',
//           description: '',
//           preview_image: '',
//         });
//       }
//     : () => openObjectBrowser({ mode: 'link' }),
//   value: data.href && flattenToAppURL(data.href),
// },
