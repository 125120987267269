/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React from 'react';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import TopBar from 'volto-trc-project/components/TopBar/TopBar';
import { InView } from 'react-intersection-observer';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { UniversalLink } from '@plone/volto/components';

import { Logo, Navigation } from '@plone/volto/components';

// LanguageSelector,Container,
// SearchWidget,

const Header = (props) => {
  const [inView, setInView] = React.useState();
  return (
    <>
      <Segment
        basic
        className={cx('header', {
          'header-in-view': inView,
          'header-out-of-view': !inView,
        })}
        role="banner"
      >
        <div className="top-menu">
          <Link title="Home" to="/">
            Home
          </Link>
          {' | '}
          <Link title="About us" to="/about-us/">
            About Us
          </Link>
        </div>
        <div className="logo-nav-wrapper">
          <div className="logo">
            <Logo />
          </div>

          <Navigation pathname={props.pathname} />
        </div>

        <TopBar content={props.content} />
      </Segment>
      <InView
        as="div"
        className="header-visibility-sensor"
        onChange={(inView, entry) => {
          setInView(inView);
        }}
      >
        {' '}
      </InView>
      <div id="header-spacer"></div>
    </>
  );
};

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);

//      <div className="tools-search-wrapper">
//        <LanguageSelector />
//        <div className="search">
//          <SearchWidget pathname={props.pathname} />
//        </div>
//      </div>
