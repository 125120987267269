const EventDetailsSchema = () => ({
  title: 'Event details',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
  ],
  properties: {},
  required: [],
});

export default EventDetailsSchema;
