import React from 'react';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';
import Schema from './schema';
import { SidebarPortal } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import { Segment } from 'semantic-ui-react';

import SearchInputView from './SearchInputView';

const SearchInputEdit = (props) => {
  const { block, onChangeBlock, data, selected } = props;
  const schema = Schema(props);

  return (
    <>
      <Segment>
        <SearchInputView
          {...props}
          content={props.properties}
          path={getBaseUrl(props.pathname)}
          mode="edit"
        />
      </Segment>
      <SidebarPortal selected={selected}>
        <InlineForm
          schema={schema}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
    </>
  );
};

export default SearchInputEdit;
