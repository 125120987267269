import React from 'react';
import { BlockDataForm } from '@plone/volto/components';
import Schema from './schema';
import EventDetailsBlockView from './EventDetailsBlockView';
import { SidebarPortal } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
import { Message } from 'semantic-ui-react';

const SearchBlockEdit = (props) => {
  const { block, onChangeBlock, data, selected } = props;
  const schema = Schema();
  return (
    <>
      <EventDetailsBlockView
        {...props}
        path={getBaseUrl(props.pathname)}
        mode="edit"
      />
      <Message>Event details block</Message>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
    </>
  );
};

export default SearchBlockEdit;
