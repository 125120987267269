import imagesSVG from '@plone/volto/icons/images.svg';
import TeaserViewBlock from './View';
import TeaserEditBlock from './Edit';

export default (config) => {
  config.blocks.blocksConfig.teaser = {
    id: 'teaser',
    title: 'Teaser',
    icon: imagesSVG,
    group: 'common',
    view: TeaserViewBlock,
    edit: TeaserEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
