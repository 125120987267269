import EventView from './Event/EventView';
import StoryView from './Story/StoryView';
import DefaultView from './DefaultView/DefaultView';
import NewsItemView from './NewsItem/NewsItemView';

export default (config) => {
  config.views.defaultView = DefaultView;
  config.views.contentTypesViews.story = StoryView;
  config.views.contentTypesViews.Event = EventView;
  config.views.contentTypesViews.NewsItem = NewsItemView;

  return config;
};
