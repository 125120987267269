import React from 'react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { useLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { compose } from 'redux';
import InfoWindow from './InfoWindow';

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import './map.css';

const mapOptions = {
  center: {
    lat: 44.986656,
    lng: -93.258133,
  },
  zoom: 6,
  minZoom: 2,
  maxZoom: 17,
};

const withClientOnly = (WrappedComponent) => {
  return (props) =>
    __CLIENT__ ? <WrappedComponent {...props} /> : (props) => null;
};

const withMarkerIcons = (WrappedComponent) => {
  return (props) => {
    const [icons, setIcons] = React.useState({});
    const loadedLibs = useLazyLibs(['leaflet']);

    const { leaflet } = loadedLibs || {};

    React.useEffect(() => {
      // fix markers broken by webpack
      if (!leaflet) return;
      const { Icon } = leaflet;

      delete Icon.Default.prototype._getIconUrl;

      Icon.Default.mergeOptions({
        iconRetinaUrl,
        iconUrl,
        shadowUrl,
      });
      // default: Icon.Default,

      setIcons({ default: Icon.Default });
    }, [leaflet]);

    return leaflet ? <WrappedComponent {...props} icons={icons} /> : null;
  };
};

const getStyle = (data) => {
  const { height, aspectRatio } = data;
  return height
    ? { height }
    : aspectRatio
    ? { aspectRatio }
    : { height: '80vh' };
};

const StoryMap = (props) => {
  const { reactLeaflet, data } = props; // leaflet: L
  const { MapContainer, TileLayer, Marker, Popup } = reactLeaflet;

  const {
    latitude,
    longitude,
    file_data,
    columns = [],
    showDirectionsLink,
  } = data;

  const [zoom, setZoom] = React.useState(mapOptions.zoom);
  const nodeId = `leaflet-map-${props.block || props.id}`;

  return (
    <div id={nodeId} className="story-map-block">
      <div style={getStyle(data)}>
        <MapContainer
          className="markercluster-map"
          style={{ height: '100%' }}
          center={[mapOptions.center.lat, mapOptions.center.lng]}
          zoom={zoom}
          minZoom={mapOptions.minZoom}
          maxZoom={mapOptions.maxZoom}
          whenCreated={(map) => {
            // setMap(map);
            map.on('zoom', (e) => {
              setZoom(e.target._zoom);
            });
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {latitude &&
            longitude &&
            file_data?.data?.map((m, i) => {
              const lat = parseFloat(m[latitude] || 0.0);
              const long = -1 * parseFloat(m[longitude] || 0.0);
              return lat && long ? (
                <Marker key={i} position={[lat, long]}>
                  <Popup>
                    <InfoWindow
                      columns={columns}
                      data={m}
                      showDirectionsLink={showDirectionsLink}
                      latitude={lat}
                      longitude={long}
                    />
                  </Popup>
                </Marker>
              ) : (
                ''
              );
            })}
        </MapContainer>
      </div>
    </div>
  );
};

export default compose(
  withClientOnly,
  withMarkerIcons,
  injectLazyLibs(['reactLeaflet', 'leaflet']),
)(StoryMap);
