import React from 'react';
import { cloneDeep } from 'lodash';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import config from '@plone/volto/registry';

export const Title = ({ value }) => {
  return <h5>{value}</h5>;
};

export const LabeledValue = ({ value, column, label }) => {
  return (
    <>
      <strong>{label || column}</strong> {value}
      <br />
    </>
  );
};

LabeledValue.schemaExtender = (schema, data) => {
  if (!data.renderer === 'labeledValue') return schema;

  schema = cloneDeep(schema);

  schema.properties.label = {
    title: 'Custom label',
    description: 'Custom label',
  };

  schema.fieldsets[0].fields.push('label');

  return schema;
};

export const Directions = ({ latitude, longitude }) => (
  <p>
    <a
      href={`https://www.google.com/maps/place/${latitude},${-1 * longitude}`}
      target="_blank"
      rel="noreferrer"
    >
      Directions
    </a>
  </p>
);

const InfoWindow = (props) => {
  const {
    columns = [],
    latitude,
    longitude,
    showDirectionsLink = false,
    data,
  } = props;
  const { valueRenderers = {} } = config.blocks.blocksConfig.storymap;

  return (
    <div className="marker-tooltip">
      <p>
        {columns.map(({ id, column, renderer, ...rest }) => {
          const Renderer = renderer
            ? valueRenderers.find(({ id }) => id === renderer).view
            : LabeledValue;
          return (
            <Renderer key={id} column={column} value={data[column]} {...rest} />
          );
        })}
      </p>
      <Grid columns={2}>
        <Grid.Row>
          {showDirectionsLink && (
            <Grid.Column>
              <Directions latitude={latitude} longitude={longitude} />
            </Grid.Column>
          )}
          {/* <Grid.Column> */}
          {/*   <p> */}
          {/*     <Link as="a" to={''}> */}
          {/*       Related stories */}
          {/*     </Link> */}
          {/*   </p> */}
          {/* </Grid.Column> */}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default InfoWindow;
