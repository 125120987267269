import React from 'react';

const SectionEdit = ({ data }) => (
  <div className="block-section">
    <fieldset>
      <legend>Section divider</legend>
      All content above this will fit the entire width, pushing the right column
      down.
    </fieldset>
  </div>
);

export default SectionEdit;
