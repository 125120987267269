import React from 'react';
// import { SlotRenderer } from '@plone/volto/components';
// import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import config from '@plone/volto/registry';
// import { isSlotAvailable } from '@plone/volto/helpers';
// import { Container, Grid, Segment } from 'semantic-ui-react';
import { ResponsiveContainer } from 'volto-trc-project/components';
import { NavLink } from 'react-router-dom';

function MobileOnly(props) {
  return (
    <div className="mobileonly">
      <nav className="context-navigation">
        <div role="list" className="ui list">
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/iowa">
                IA
              </NavLink>
            </div>
          </div>

          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/minnesota">
                MN
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/nebraska">
                NE
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/north-dakota">
                ND
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/south-dakota">
                SD
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/wisconsin">
                WI
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

function TabletOnly(props) {
  return (
    <div className="tabletonly">
      <nav className="context-navigation">
        <div role="list" className="ui list">
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/iowa">
                IA
              </NavLink>
            </div>
          </div>

          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/minnesota">
                MN
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/nebraska">
                NE
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/north-dakota">
                ND
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/south-dakota">
                SD
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/wisconsin">
                WI
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
      <div className="searchInput-wrapper">
        <form action="/search" className="ui form">
          <div className="field searchbox">
            <div className="ui transparent input">
              <input
                type="text"
                aria-label="Search"
                name="SearchableText"
                autoComplete="off"
                placeholder="Search Site"
                title="Search"
              ></input>
            </div>
            <button aria-label="Search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                className="icon"
              >
                <path
                  fillRule="evenodd"
                  d="M7,16 C7,11.038 11.037,7 16,7 C20.963,7 25,11.038 25,16 C25,20.962 20.963,25 16,25 C11.037,25 7,20.962 7,16 L7,16 Z M32.707,31.293 L24.448,23.034 C26.039,21.125 27,18.673 27,16 C27,9.935 22.065,5 16,5 C9.935,5 5,9.935 5,16 C5,22.065 9.935,27 16,27 C18.673,27 21.125,26.039 23.034,24.448 L31.293,32.707 L32.707,31.293 Z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function LargescreenOnly(props) {
  return (
    <div className="largescreenonly">
      <nav className="context-navigation">
        <div role="list" className="ui list">
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/iowa">
                Iowa
              </NavLink>
            </div>
          </div>

          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/minnesota">
                Minnesota
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/nebraska">
                Nebraska
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/north-dakota">
                North Dakota
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/south-dakota">
                South Dakota
              </NavLink>
            </div>
          </div>
          <div role="listitem" className="item level-1">
            <div className="content">
              <NavLink title="" to="/policy/wisconsin">
                Wisconsin
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
      <div className="searchInput-wrapper">
        <form action="/search" className="ui form">
          <div className="field searchbox">
            <div className="ui transparent input">
              <input
                type="text"
                aria-label="Search"
                name="SearchableText"
                autoComplete="off"
                placeholder="Search Site"
                title="Search"
              ></input>
            </div>
            <button aria-label="Search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                className="icon"
              >
                <path
                  fillRule="evenodd"
                  d="M7,16 C7,11.038 11.037,7 16,7 C20.963,7 25,11.038 25,16 C25,20.962 20.963,25 16,25 C11.037,25 7,20.962 7,16 L7,16 Z M32.707,31.293 L24.448,23.034 C26.039,21.125 27,18.673 27,16 C27,9.935 22.065,5 16,5 C9.935,5 5,9.935 5,16 C5,22.065 9.935,27 16,27 C18.673,27 21.125,26.039 23.034,24.448 L31.293,32.707 L32.707,31.293 Z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const TopBar = (props) => {
  const { content } = props;
  // const pathname = useLocation().pathname;
  // const { slots } = config;
  // const slotData = useSelector((state) => state.slots?.data || {});
  //
  // const available = (name) => {
  //   if (!slots[name]) {
  //     return null;
  //   }
  //   const props = { pathname, slotData, slotName: name, slots, content };
  //   return slots[name].available
  //     ? slots[name].available(props)
  //     : isSlotAvailable(props);
  // };

  // const hasTopBar = available('topBar');

  return (
    <div id="top-bar">
      <ResponsiveContainer>
        {({ parentWidth }) => (
          <>
            {parentWidth > 900 && <LargescreenOnly />}
            {parentWidth > 500 && parentWidth < 901 && <TabletOnly />}
            {parentWidth < 501 && <MobileOnly />}
          </>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default TopBar;
