import mapSVG from '@plone/volto/icons/navigation.svg';

import StoryMapView from './StoryMapView';
import StoryMapEdit from './StoryMapEdit';
import { Title, LabeledValue } from './InfoWindow';

export default (config) => {
  config.blocks.blocksConfig.storymap = {
    id: 'storymap',
    title: 'Story sites map',
    icon: mapSVG,
    group: 'common',
    view: StoryMapView,
    edit: StoryMapEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    valueRenderers: [
      {
        id: 'title',
        title: 'Title',
        view: Title,
      },
      {
        id: 'labeledValue',
        title: 'Labeled value',
        view: LabeledValue,
        schemaExtender: LabeledValue.schemaExtender,
      },
    ],
  };

  return config;
};
