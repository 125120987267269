/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';

import DownloadList from 'volto-trc-project/components/DownloadList/DownloadList';
import { flattenToAppURL, flattenHTMLToAppURL } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { getBaseUrl, hasBlocksData } from '@plone/volto/helpers';

const NewsItemDetailsView = (props) => {
  const { content } = props;
  return (
    <>
      {content.title && (
        <h1 className="documentFirstHeading">
          {content.title}
          {content.subtitle && ` - ${content.subtitle}`}
        </h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="documentImage"
          alt={content.title}
          title={content.title}
          src={
            content.image['content-type'] === 'image/svg+xml'
              ? flattenToAppURL(content.image.download)
              : flattenToAppURL(content.image.scales.mini.download)
          }
          floated="right"
        />
      )}
      {content.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: flattenHTMLToAppURL(content.text.data),
          }}
        />
      )}
    </>
  );
};

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = (props) => {
  const { content } = props;
  return (
    <Container className="view-wrapper">
      {hasBlocksData(content) ? (
        <>
          <RenderBlocks {...props} />
          <DownloadList
            {...props}
            block={getBaseUrl(props.location.pathname)}
          />
        </>
      ) : (
        <>
          <NewsItemDetailsView {...props} />
          <DownloadList
            {...props}
            block={getBaseUrl(props.location.pathname)}
          />
        </>
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
