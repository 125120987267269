/**
 * View container.
 * @module components/theme/View/View
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Portal } from 'react-portal';
import { injectIntl } from 'react-intl';
import { Helmet } from '@plone/volto/helpers';
import qs from 'query-string';
import { Grid, Container } from 'semantic-ui-react';
import config from '@plone/volto/registry';

import {
  Comments,
  // Tags,
  Toolbar,
  RenderBlocks,
} from '@plone/volto/components';
import { listActions, getContent } from '@plone/volto/actions';
import { getSlots } from '@eeacms/volto-slots/actions';
import { ContentContainer } from '@eeacms/volto-slots/components';
import {
  BodyClass,
  getBaseUrl,
  getLayoutFieldname,
  getBlocks,
} from '@plone/volto/helpers';

const ClippingMask = () => (
  <svg viewBox="0 0 476 487" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2_6)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-171.26 123.002C-189.894 141.857 -189.714 172.249 -170.858 190.883L-1.52588e-05 359.729V443H479V1.52588e-05H168.308L83.6155 -83.6954C69.4736 -97.6707 46.68 -97.5357 32.7047 -83.3938L-171.26 123.002ZM168.308 1.52588e-05H-1.52588e-05V359.729L27.0016 386.413C45.8574 405.047 76.2488 404.867 94.8827 386.011L290.413 188.151C309.047 169.295 308.867 138.904 290.011 120.27L168.308 1.52588e-05Z"
        fill="#F7EFE1"
      />
      <path
        d="M124.142 -108.117C105.286 -126.751 105.106 -157.143 123.74 -175.998L327.705 -382.394C341.68 -396.536 364.474 -396.671 378.615 -382.695L585.011 -178.73C603.867 -160.096 604.047 -129.705 585.413 -110.849L389.883 87.0109C371.249 105.867 340.857 106.047 322.002 87.4129L124.142 -108.117Z"
        fill="#BCD5CF"
      />
      <rect y="432" width="479" height="151" fill="#F7EFE1" />
    </g>
    <defs>
      <clipPath id="clip0_2_6">
        <rect width="479" height="487" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

/**
 * View container class.
 * @class View
 * @extends Component
 */
class View extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    actions: PropTypes.shape({
      object: PropTypes.arrayOf(PropTypes.object),
      object_buttons: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.arrayOf(PropTypes.object),
    }),
    listActions: PropTypes.func.isRequired,
    /**
     * Action to get the content
     */
    getContent: PropTypes.func.isRequired,
    /**
     * Pathname of the object
     */
    pathname: PropTypes.string.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
    /**
     * Version id of the object
     */
    versionId: PropTypes.string,
    /**
     * Content of the object
     */
    content: PropTypes.shape({
      /**
       * Layout of the object
       */
      layout: PropTypes.string,
      /**
       * Allow discussion of the object
       */
      allow_discussion: PropTypes.bool,
      /**
       * Title of the object
       */
      title: PropTypes.string,
      /**
       * Description of the object
       */
      description: PropTypes.string,
      /**
       * Type of the object
       */
      '@type': PropTypes.string,
      /**
       * Subjects of the object
       */
      subjects: PropTypes.arrayOf(PropTypes.string),
      is_folderish: PropTypes.bool,
    }),
    error: PropTypes.shape({
      /**
       * Error type
       */
      status: PropTypes.number,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    actions: null,
    content: null,
    versionId: null,
    error: null,
  };

  state = {
    hasObjectButtons: null,
    isClient: false,
  };

  componentDidMount() {
    this.props.listActions(getBaseUrl(this.props.pathname));
    this.props.getContent(
      getBaseUrl(this.props.pathname),
      this.props.versionId,
    );
    this.props.getSlots(getBaseUrl(this.props.pathname));
    this.setState({ isClient: true });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.listActions(getBaseUrl(nextProps.pathname));
      this.props.getContent(
        getBaseUrl(nextProps.pathname),
        this.props.versionId,
      );
      this.props.getSlots(getBaseUrl(nextProps.pathname));
    }

    if (nextProps.actions.object_buttons) {
      const objectButtons = nextProps.actions.object_buttons;
      this.setState({
        hasObjectButtons: !!objectButtons.length,
      });
    }
  }

  /**
   * Default fallback view
   * @method getViewDefault
   * @returns {string} Markup for component.
   */
  getViewDefault = () => config.views.defaultView;

  /**
   * Get view by content type
   * @method getViewByType
   * @returns {string} Markup for component.
   */
  getViewByType = () =>
    config.views.contentTypesViews[this.props.content['@type']] || null;

  /**
   * Get view by content layout property
   * @method getViewByLayout
   * @returns {string} Markup for component.
   */
  getViewByLayout = () =>
    config.views.layoutViews[
      this.props.content[getLayoutFieldname(this.props.content)]
    ] || null;

  /**
   * Cleans the component displayName (specially for connected components)
   * which have the Connect(componentDisplayName)
   * @method cleanViewName
   * @param  {string} dirtyDisplayName The displayName
   * @returns {string} Clean displayName (no Connect(...)).
   */
  cleanViewName = (dirtyDisplayName) =>
    dirtyDisplayName
      .replace('Connect(', '')
      .replace('injectIntl(', '')
      .replace(')', '')
      .replace('connect(', '')
      .toLowerCase();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const {
      views,
      blocks: { blocksConfig },
    } = config;

    const TitleView = blocksConfig.title.view;

    if (this.props.error && !this.props.connectionRefused) {
      let FoundView;
      if (this.props.error.status === undefined) {
        // For some reason, while development and if CORS is in place and the
        // requested resource is 404, it returns undefined as status, then the
        // next statement will fail
        FoundView = views.errorViews.corsError;
      } else {
        FoundView = views.errorViews[this.props.error.status.toString()];
      }
      if (!FoundView) {
        FoundView = views.errorViews['404']; // default to 404
      }
      return (
        <div id="view">
          <FoundView {...this.props} />
        </div>
      );
    }
    if (!this.props.content) {
      return <span />;
    }
    const RenderedView =
      this.getViewByType() || this.getViewByLayout() || this.getViewDefault();

    const blocks = getBlocks(this.props.content);

    const hasSectionDivider = !!blocks.find(
      (b) => b[1]?.['@type'] === 'sectionDivider',
    );
    let blocksAboveSectionDivider = [];
    for (const b of blocks) {
      if (b[1]?.['@type'] === 'sectionDivider') break;
      blocksAboveSectionDivider.push(b);
    }

    const topBlocks = hasSectionDivider
      ? blocksAboveSectionDivider
      : blocks?.filter((b) => b[1]?.styles?.style_name === 'moveToTop');
    let blocksBelowSectionDivider = [];
    for (let i = blocks.length - 1; i > -1; i--) {
      const b = blocks[i];
      if (b[1]?.['@type'] === 'sectionDivider') break;
      blocksBelowSectionDivider.splice(0, 0, b);
    }

    // || b[1]?.['@type'] === 'sectionDivider',
    const bottomBlocks = hasSectionDivider
      ? blocksBelowSectionDivider
      : blocks?.filter((b) => b[1]?.styles?.style_name !== 'moveToTop');
    const contentWithTopBlocks = topBlocks?.length
      ? {
          ...this.props.content,
          blocks: Object.assign(
            {},
            ...topBlocks.map((b) => ({ [b[0]]: b[1] })),
          ),
          blocks_layout: {
            items: topBlocks.map((b) => b[0]),
          },
        }
      : null;
    const contentWithBottomBlocks = bottomBlocks?.length
      ? {
          ...this.props.content,
          blocks: Object.assign(
            {},
            ...bottomBlocks.map((b) => ({ [b[0]]: b[1] })),
          ),
          blocks_layout: {
            items: bottomBlocks.map((b) => b[0]),
          },
        }
      : this.props.content;

    // console.log('blocks', {
    //   topBlocks,
    //   bottomBlocks,
    //   contentWithTopBlocks,
    //   contentWithBottomBlocks,
    // });
    // console.log('this view');

    return (
      <div id="view" className={hasSectionDivider ? 'has-section-divider' : ''}>
        <Helmet>
          {this.props.content.language && (
            <html lang={this.props.content.language.token} />
          )}
          <title>{this.props.content.title}</title>
          <meta name="description" content={this.props.content.description} />
        </Helmet>
        {/* Body class if displayName in component is set */}
        <BodyClass
          className={
            RenderedView.displayName
              ? `view-${this.cleanViewName(RenderedView.displayName)}`
              : null
          }
        />

        {topBlocks.length ? (
          <div className="top-part">
            <Container>
              <RenderBlocks {...this.props} content={contentWithTopBlocks} />
            </Container>
          </div>
        ) : (
          ''
        )}

        {['story', 'Document'].includes(this.props.content?.['@type']) ? (
          <>
            <Container className="document-container">
              <Grid>
                <Grid.Row>
                  <Grid.Column className="content-body" width={12}>
                    <header className="heading-wrapper">
                      {this.props.content['@type'] === 'story' && (
                        <div
                          className="storybg"
                          style={{
                            //maxHeight: '360px',
                            height: '100%',
                            width: '33%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            backgroundImage: this.props.content?.image
                              ? `url(${this.props.content.image.scales.large.download})`
                              : null,
                          }}
                        >
                          <ClippingMask />
                        </div>
                      )}
                      <TitleView
                        properties={this.props.content}
                        data={
                          getBlocks(this.props.content).find(
                            ([uid, block]) => block['@type'] === 'title',
                          )?.[1] || { '@type': 'title' }
                        }
                      />
                    </header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </>
        ) : (
          ''
        )}

        {['NewsItem', 'Event'].indexOf(this.props.content['@type']) > -1 ? (
          <div className="content-wrapper">
            <RenderedView
              content={contentWithBottomBlocks}
              location={this.props.location}
              token={this.props.token}
              history={this.props.history}
            />
            {this.props.content.allow_discussion && (
              <Comments pathname={this.props.pathname} />
            )}
            {this.state.isClient && (
              <Portal node={document.getElementById('toolbar')}>
                <Toolbar
                  pathname={this.props.pathname}
                  inner={<span />}
                  activity="view"
                />
              </Portal>
            )}
          </div>
        ) : (
          <ContentContainer content={this.props.content}>
            <RenderedView
              content={contentWithBottomBlocks}
              location={this.props.location}
              token={this.props.token}
              history={this.props.history}
            />

            {/* Add opt-in social sharing if required, disabled by default */}
            {/* In the future this might be parameterized from the app config */}
            {/* <SocialSharing
          url={typeof window === 'undefined' ? '' : window.location.href}
          title={this.props.content.title}
          description={this.props.content.description || ''}
          /> */}

            {this.props.content.allow_discussion && (
              <Comments pathname={this.props.pathname} />
            )}
            {this.state.isClient && (
              <Portal node={document.getElementById('toolbar')}>
                <Toolbar
                  pathname={this.props.pathname}
                  inner={<span />}
                  activity="view"
                />
              </Portal>
            )}
          </ContentContainer>
        )}
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      actions: state.actions.actions,
      token: state.userSession.token,
      content: state.content.data,
      error: state.content.get.error,
      apiError: state.apierror.error,
      connectionRefused: state.apierror.connectionRefused,
      pathname: props.location.pathname,
      versionId:
        qs.parse(props.location.search) &&
        qs.parse(props.location.search).version_id,
    }),
    {
      listActions,
      getContent,
      getSlots,
    },
  ),
)(View);
