import downloadSVG from '@plone/volto/icons/download.svg';
import FileCardBlockView from './FileCardBlockView';
import FileCardBlockEdit from './FileCardBlockEdit';

export default (config) => {
  config.blocks.blocksConfig.fileCard = {
    id: 'fileCard',
    title: 'File Card',
    icon: downloadSVG,
    group: 'common',
    view: FileCardBlockView,
    edit: FileCardBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
