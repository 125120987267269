import React from 'react';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import { Label } from 'semantic-ui-react';

export default (props) => {
  const state = props.content?.review_state || 'no state';

  return (
    <Plug
      id="review-state"
      pluggable="main.toolbar.bottom"
      key={props.content?.['@id']}
    >
      <div className="review-state-indicator">
        <Label size="mini" color={state === 'published' ? 'green' : 'red'}>
          {state}
        </Label>
      </div>
    </Plug>
  );
};
