import React from 'react';
// import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import DownloadList from 'volto-trc-project/components/DownloadList/DownloadList';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';
import SocialIconsView from 'volto-trc-project/components/blocks/SocialIcons/SocialIconsView';

const defaultSocialIcons = [
  {
    '@id': 'facebook',
    href: 'https://www.facebook.com/greatplains.trac/',
    title: 'Facebook',
  },
  {
    '@id': 'twitter',
    href: 'https://twitter.com/gpTRAC',
    title: 'Twitter',
  },
  {
    '@id': 'linkedin',
    href: 'https://www.linkedin.com/company/gptrac/',
    title: 'LinkedIn',
  },
];

const StoryView = (props) => {
  const { blocksConfig } = config.blocks;
  const { content = {} } = props;
  const { subtitle, description } = content;

  return (
    <div id="story-view" className="">
      <div className="ui container">
        {subtitle && <h3 className="story-subtitle">{subtitle}</h3>}
        {description ? <p className="summary">{description}</p> : ''}
        <RenderBlocks
          {...props}
          blocksConfig={{
            ...blocksConfig,
            title: { ...blocksConfig.title, view: () => null },
          }}
        />
        <div className="social-icons-footer">
          <div>
            This post was originally published by NCTRC Staff and provided to
            NCTRC for syndication.
          </div>
          <div className="soc-link-wrap">
            <SocialIconsView data={{ links: defaultSocialIcons }} />
          </div>
        </div>
        <DownloadList {...props} block={getBaseUrl(props.location.pathname)} />
      </div>
    </div>
  );
};

export default injectIntl(StoryView);
