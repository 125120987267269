import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';

import MapSchema from './schema';
import LeafletMapBlockView from './LeafletMapBlockView';

const LeafletMapBlockEdit = (props) => {
  const { data = {}, selected, block, onChangeBlock } = props;
  const schema = MapSchema(props);
  return (
    <>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
      <LeafletMapBlockView {...props} limit={500} />
    </>
  );
};

export default withBlockExtensions(LeafletMapBlockEdit);
