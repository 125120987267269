export const defaultDisclaimer = `
The National Consortium of Telehealth Resource Centers (NCTRC) is an
affiliation of the 14 Telehealth Resource Centers (TRCs) — 12 regional TRCs and
2 national TRCs, funded individually through cooperative agreements from the
Health Resources & Services Administration, Office for the Advancement of
Telehealth. The goal of the NCTRC is to increase the consistency, efficiency,
and impact of federally funded telehealth technical assistance services. This
was made possible by 14 Telehealth Resource Centers and administered through
grant.
`;
