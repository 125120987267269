import React from 'react';
import { Placeholder, Container } from 'semantic-ui-react';

export default function DesignSystem(props) {
  return (
    <div className="">
      <div id="view">
        <Container>
          <div className="design-system">
            <h4>__Headers _______________________________</h4>
            <h1 className="documentFirstHeading">
              Main title as documentFirstHeading
            </h1>
            <h2>This is Heading Two</h2>
            <h3>This is Heading Three</h3>
            <h4>This is Heading Four</h4>
            <h5>This is Heading Five</h5>
            <h4>__Text styles _______________________________</h4>
            <p>
              <b>Paragraph here </b>- Lorem ipsum dolor sit amet,{' '}
              <b>bolded text</b> consectetur adipiscing elit. Maecenas aliquam
              convallis lectus. Donec in sem eu lectus aliquam congue. Nulla
              laoreet egestas eros, ac tincidunt massa congue eu. Curabitur id
              nulla eget leo bibendum tincidunt. <i>Italic text here</i>{' '}
              placerat tristique sapien, sit amet viverra nunc imperdiet
              porttitor. Vivamus interdum, sem ornare iaculis imperdiet, tellus
              urna pulvinar leo, sed vulputate diam justo id sem. Proin ornare
              ex sit amet justo ultrices, at ultrices risus hendrerit. Phasellus
              non sem vel tortor pharetra pellentesque. Sed vitae turpis at
              tortor consequat mattis et nec purus.
            </p>
            <a href="/success-stories/a-web-of-care-for-our-elderly-montgomery-county-memorial-hospital-works-with-nursing-facilities-to-provide-safer-care">
              <div className="listing-body">
                <h4>This is a listing title link - Heading Four</h4>
              </div>
            </a>
            <div className="view-viewview contenttype-story">
              <header className="document-header">
                <h1 className="documentFirstHeading">
                  Story Main title with custom background with tags above and
                  date/author below
                </h1>
              </header>
            </div>
            <h4>__Quotations _______________________________</h4>
            <blockquote className="callout">
              <b>Blockquote - </b>Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur tempus nisi a pulvinar vulputate. In a
              arcu urna. Etiam dictum leo sit amet blandit vehicula. Sed a enim
              vitae erat mattis aliquam a ac ipsum. Morbi sagittis mauris sit
              amet tempor auctor. Etiam eu ante lacus. Fusce vitae egestas leo.
              Quisque orci massa, fermentum eu varius in, porttitor nec urna.{' '}
            </blockquote>
            <p className="callout">
              <b>Callout</b> - ipsum dolor sit amet, consectetur adipiscing
              elit. Curabitur tempus nisi a pulvinar vulputate. In a arcu urna.
              Etiam dictum leo sit amet blandit vehicula. Sed a enim vitae erat
              mattis aliquam a ac ipsum. Morbi sagittis mauris sit amet tempor
              auctor. Etiam eu ante lacus. Fusce vitae egestas leo. Quisque orci
              massa, fermentum eu varius in, porttitor nec urna.{' '}
            </p>
            <p>
              <a
                href="#footnote-rJSEE"
                id="ref-rJSEE"
                aria-describedby="footnote-label"
              >
                <span className="citation-indice">
                  {' '}
                  <b>Footnote </b> number one
                </span>
              </a>
            </p>
            <h4>__Lists _______________________________</h4>
            <h5>Bullet list (unstyled via editor)</h5>
            <ul>
              <li>item one</li>
              <li>item two</li>
              <li>item three</li>
              <li>item four</li>
            </ul>
            <h5>Ordered list (unstyled via editor)</h5>
            <ol>
              <li>item one</li>
              <li>item two</li>
              <li>item three</li>
              <li>item four</li>
            </ol>
            <h4>__Elements _______________________________</h4>
            <h5>Buttons</h5>
            <button type="submit" className="ui primary button">
              Primary button
            </button>
            <button className="ui button">Regular button</button>
            <h5>Tags (centered)</h5>
            <div className="story-tags">
              <div className="ui container tags">
                <a className="ui label" href="/search?Subject=CAH">
                  CAH
                </a>
                <a className="ui label" href="/search?Subject=Elder Care">
                  Elder Care
                </a>
                <a className="ui label" href="/search?Subject=Iowa">
                  Iowa
                </a>
              </div>
            </div>
            <h5>Table</h5>
            <table className="ui celled fixed table">
              <tbody className="">
                <tr className="">
                  <th className="">
                    <p>table</p>
                  </th>
                  <th className="">
                    <p>heading</p>
                  </th>
                </tr>
                <tr className="">
                  <td className="">
                    <p>cell one</p>
                  </td>
                  <td className="">
                    <p>cell two</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="">
                    <p>cell one</p>
                  </td>
                  <td className="">
                    <p>cell two</p>
                  </td>
                </tr>
                <tr className="">
                  <td className="">
                    <p>cell one</p>
                  </td>
                  <td className="">
                    <p>cell two</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Story Byline (centered)</h5>
            <div className="story-byline">
              By <a href="/">no facility</a> • April 20, 2021
            </div>
            <h5>Right Column link</h5>
            <p className="blue-demo-box styled">
              <strong>Top Stories</strong>
            </p>
            <h4>__ Images _______________________________</h4>
            <h5>Centered</h5>
            <p className="block image align center">
              <img
                className=""
                src="https://images.unsplash.com/photo-1532879311112-62b7188d28ce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2555&q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <h5>Left aligned (large)</h5>
            <p className="block image align left">
              <img
                className=""
                src="https://images.unsplash.com/photo-1626121216826-eb2f91fb1314?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2551&amp;q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tempus nisi a pulvinar vulputate. In a arcu urna. Etiam dictum leo
              sit amet blandit vehicula. Sed a enim vitae erat mattis aliquam a
              ac ipsum. Morbi sagittis mauris sit amet tempor auctor. Etiam eu
              ante lacus. Fusce vitae egestas leo. Quisque orci massa, fermentum
              eu varius in, porttitor nec urna. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Curabitur tempus nisi a pulvinar
              vulputate. In a arcu urna. Etiam dictum leo sit amet blandit
              vehicula. Sed a enim vitae erat mattis aliquam a ac ipsum. Morbi
              sagittis mauris sit amet tempor auctor. Etiam eu ante lacus. Fusce
              vitae egestas leo. Quisque orci massa, fermentum eu varius in,
              porttitor nec urna. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur tempus nisi a pulvinar vulputate. In a
              arcu urna. Etiam dictum leo sit amet blandit vehicula. Sed a enim
              vitae erat mattis aliquam a ac ipsum. Morbi sagittis mauris sit
              amet tempor auctor. Etiam eu ante lacus. Fusce vitae egestas leo.
              Quisque orci massa, fermentum eu varius in, porttitor nec
              urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Curabitur tempus nisi a pulvinar vulputate. In a arcu urna. Etiam
              dictum leo sit amet blandit vehicula. Sed a enim vitae erat mattis
              aliquam a ac ipsum. Morbi sagittis mauris sit amet tempor auctor.
              Etiam eu ante lacus. Fusce vitae egestas leo. Quisque orci massa,
              fermentum eu varius in, porttitor nec urna. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Curabitur tempus nisi a
              pulvinar vulputate. In a arcu urna. Etiam dictum leo sit amet
              blandit vehicula. Sed a enim vitae erat mattis aliquam a ac ipsum.
              Morbi sagittis mauris sit amet tempor auctor. Etiam eu ante lacus.
              Fusce vitae egestas leo. Quisque orci massa, fermentum eu varius
              in, porttitor nec urna. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur tempus nisi a pulvinar vulputate. In a
              arcu urna. Etiam dictum leo sit amet blandit vehicula. Sed a enim
              vitae erat mattis aliquam a ac ipsum. Morbi sagittis mauris sit
              amet tempor auctor. Etiam eu ante lacus. Fusce vitae egestas leo.
              Quisque orci massa, fermentum eu varius in, porttitor nec
              urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Curabitur tempus nisi a pulvinar vulputate. In a arcu urna. Etiam
              dictum leo sit amet blandit vehicula. Sed a enim vitae erat mattis
              aliquam a ac ipsum. Morbi sagittis mauris sit amet tempor auctor.
              Etiam eu ante lacus. Fusce vitae egestas leo. Quisque orci massa,
              fermentum eu varius in, porttitor nec urna.{' '}
            </p>
            <h5>Right aligned (medium size)</h5>
            <p className="block image align right">
              <img
                className="medium"
                src="https://images.unsplash.com/photo-1626121216826-eb2f91fb1314?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2551&amp;q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tempus nisi a pulvinar vulputate. In a arcu urna. Etiam dictum leo
              sit amet blandit vehicula. Sed a enim vitae erat mattis aliquam a
              ac ipsum. Morbi sagittis mauris sit amet tempor auctor. Etiam eu
              ante lacus. Fusce vitae egestas leo. Quisque orci massa, fermentum
              eu varius in, porttitor nec urna. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Curabitur tempus nisi a pulvinar
              vulputate. In a arcu urna. Etiam dictum leo sit amet blandit
              vehicula. Sed a enim vitae erat mattis aliquam a ac ipsum. Morbi
              sagittis mauris sit amet tempor auctor. Etiam eu ante lacus. Fusce
              vitae egestas leo. Quisque orci massa, fermentum eu varius in,
              porttitor nec urna. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur tempus nisi a pulvinar vulputate. In a
              arcu urna. Etiam dictum leo sit amet blandit vehicula. Sed a enim
              vitae erat mattis aliquam a ac ipsum. Morbi sagittis mauris sit
              amet tempor auctor. Etiam eu ante lacus. Fusce vitae egestas leo.
              Quisque orci massa, fermentum eu varius in, porttitor nec
              urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Curabitur tempus nisi a pulvinar vulputate. In a arcu urna. Etiam
              dictum leo sit amet blandit vehicula. Sed a enim vitae erat mattis
              aliquam a ac ipsum. Morbi sagittis mauris sit amet tempor auctor.
              Etiam eu ante lacus. Fusce vitae egestas leo. Quisque orci massa,
              fermentum eu varius in, porttitor nec urna. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Curabitur tempus nisi a
              pulvinar vulputate. In a arcu urna. Etiam dictum leo sit amet
              blandit vehicula. Sed a enim vitae erat mattis aliquam a ac ipsum.
              Morbi sagittis mauris sit amet tempor auctor. Etiam eu ante lacus.
              Fusce vitae egestas leo. Quisque orci massa, fermentum eu varius
              in, porttitor nec urna. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur tempus nisi a pulvinar vulputate. In a
              arcu urna. Etiam dictum leo sit amet blandit vehicula. Sed a enim
              vitae erat mattis aliquam a ac ipsum. Morbi sagittis mauris sit
              amet tempor auctor. Etiam eu ante lacus. Fusce vitae egestas leo.
              Quisque orci massa, fermentum eu varius in, porttitor nec
              urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Curabitur tempus nisi a pulvinar vulputate. In a arcu urna. Etiam
              dictum leo sit amet blandit vehicula. Sed a enim vitae erat mattis
              aliquam a ac ipsum. Morbi sagittis mauris sit amet tempor auctor.
              Etiam eu ante lacus. Fusce vitae egestas leo. Quisque orci massa,
              fermentum eu varius in, porttitor nec urna.{' '}
            </p>
            <h5>Full width</h5>
            <p className="block image align full">
              <img
                className="full-width"
                src="https://images.unsplash.com/photo-1532879311112-62b7188d28ce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2555&q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <h5>Image Scales</h5>
            <h5>> Large</h5>
            <p className="block image align center">
              <img
                className="large"
                src="https://images.unsplash.com/photo-1626121216826-eb2f91fb1314?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2551&amp;q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <h5>> Medium</h5>
            <p className="block image align center">
              <img
                className="medium"
                src="https://images.unsplash.com/photo-1626121216826-eb2f91fb1314?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2551&amp;q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <h5>> Small</h5>
            <p className="block image align center">
              <img
                className="small"
                src="https://images.unsplash.com/photo-1626121216826-eb2f91fb1314?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=2551&amp;q=80"
                alt=""
                loading="lazy"
              ></img>
            </p>
            <h4>__ Video _______________________________</h4>
            <div className="block video align center">
              <div className="video-inner">
                <div className="ui active embed">
                  <i aria-hidden="true" className="play icon"></i>
                  <div className="embed">
                    <iframe
                      scrolling="no"
                      src="//www.youtube.com/embed/tdWV2xEyOfE?autohide=true&amp;amp;autoplay=false&amp;amp;color=%23444444&amp;amp;hq=true&amp;amp;jsapi=false&amp;amp;modestbranding=false&amp;amp;rel=1"
                      title="Embedded content from youtube."
                      width="100%"
                      height="100%"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <h4>__ Forms _______________________________</h4>
            <h5>Text input</h5>
            <div className="ui fluid input">
              <input
                type="text"
                id="c7721707-2d83-4752-92f3-694cfe38e6e4-searchtext"
                value=""
                placeholder="Search..."
              ></input>
            </div>
            <h5>Email address (required)</h5>
            <div className="inline required field text">
              <div className="ui grid">
                <div className="stretched row">
                  <div className="four wide column">
                    <div className="wrapper">
                      <label
                        id="fieldset-undefined-field-label-email_address"
                        for="field-email_address"
                      >
                        Email address
                      </label>
                    </div>
                  </div>
                  <div className="eight wide column">
                    <div className="ui input">
                      <input
                        type="text"
                        id="field-email_address"
                        name="email_address"
                        value=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4>
            __Tabs block and quote block to the right
            _______________________________
          </h4>
          <div className="quoteBlock">
            <div>
              <p>This is my quote block</p>
              <div className="grid-teaser-item top">
                <a href="/2i4pxsfkb5061.jpg">
                  <h3>Download this</h3>
                  <p>More details if you download</p>
                </a>
              </div>
              <div className="ui card">
                <div className="content">
                  <Placeholder className="ui mini right floated image" />
                  <div className="header">Something about my file</div>
                  <div className="meta">877 kB</div>
                  <div className="description">It's cool, download it</div>
                </div>
                <div className="extra content">
                  <a href="/" rel="noreferrer" title="Download">
                    <button className="ui button">Download</button>
                  </a>
                </div>
              </div>
              <p></p>
            </div>
          </div>
          <div className="tabs-block default light flex-start">
            <div className="styled">
              <div className="default tabs inline">
                <div className="ui left menu">
                  <a className="active item">
                    <span className="menu-item-count">1</span>
                    <p className="menu-item-text">News</p>
                  </a>
                  <a className="item">
                    <span className="menu-item-count">2</span>
                    <p className="menu-item-text">Events</p>
                  </a>
                  <a className="item">
                    <span className="menu-item-count">3</span>
                    <p className="menu-item-text">Recipes</p>
                  </a>
                  <a className="item">
                    <span className="menu-item-count">4</span>
                    <p className="menu-item-text">Tab 4</p>
                  </a>
                </div>
                <div className="ui bottom attached segment active tab">
                  {' '}
                  <div>
                    <p>News listing here</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
