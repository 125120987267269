import zoomSVG from '@plone/volto/icons/zoom.svg';
import SearchInputView from './SearchInputView';
import SearchInputEdit from './SearchInputEdit';

export default (config) => {
  config.blocks.blocksConfig.searchInput = {
    id: 'searchInput',
    title: 'Search input',
    icon: zoomSVG,
    group: 'layout',
    view: SearchInputView,
    edit: SearchInputEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
