import { Button } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import LoginBlockView from 'volto-trc-project/components/blocks/Login/LoginBlockView';
import GptracLogo from 'volto-trc-project/customizations/volto/components/theme/Logo/gptrac_reversed.svg';
import { Link } from 'react-router-dom'; // NavLink,
import SocialIconsView from 'volto-trc-project/components/blocks/SocialIcons/SocialIconsView';
import { defaultDisclaimer } from './constants';
import React from 'react';

const DefaultGlobalActions = () => (
  <>
    <Link title="Site" to="/">
      Home
    </Link>
    {' | '}
    <Link title="Site" to="/about-us/">
      About Us
    </Link>
    {' | '}
    <Link title="Site" to="/stories/">
      Stories
    </Link>
    {' | '}
    <Link title="Site" to="/news/">
      News
    </Link>
    {' | '}
    <Link title="Site" to="/events/">
      Events
    </Link>
  </>
);

const Actions = ({ links }) => {
  return links.map(({ title, url }, index) => (
    <>
      <UniversalLink key={url} href={url} title={title}>
        {title}
      </UniversalLink>
      {index !== links.length - 1 && ' | '}
    </>
  ));
};

export default (props) => {
  const { data = {} } = props;

  return (
    <div className="footer">
      <div className="footer-logo">
        <Link title="Site" to="/">
          <img
            width="150"
            title="GPTRAC"
            src={GptracLogo}
            alt="GPTRAC Logo"
            className="logo-footer"
          />
        </Link>
      </div>

      <div className="social-footer">
        <h3>Social Media</h3>
        <SocialIconsView data={{ links: data.socialIcons }} />
      </div>

      <div className="footer-nav-wrapper">
        <nav className="footer-nav">
          {data.globalActions?.length ? (
            <Actions links={data.globalActions} />
          ) : (
            <DefaultGlobalActions />
          )}
        </nav>
        <div className="back-top">
          <Link
            as="a"
            title="Site"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            BACK TO TOP
          </Link>
        </div>
      </div>

      <div className="dsclmr">
        <p>{data.disclaimer ?? defaultDisclaimer}</p>
      </div>

      <div className="login-footer">
        <LoginBlockView />
      </div>
    </div>
  );
};
