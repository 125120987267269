import React from 'react';
import pb from 'pretty-bytes';
import { Card, Image, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';

import fileSVG from '@plone/volto/icons/file.svg';
import downloadSVG from '@plone/volto/icons/move-down.svg';

export default (props) => {
  const { item } = props;

  return (
    <Card key={item['@id']}>
      <Card.Content>
        {item.image ? (
          <a href={item.file?.download} title="Download">
            <Image src={item.image?.scales?.mini?.download} />
          </a>
        ) : (
          <Icon
            name={fileSVG}
            size="26px"
            className="ui mini right floated image"
          />
        )}
        <Card.Header>
          <a href={item.file?.download} title="Download">
            {item.title}
          </a>
          <Button
            basic
            as="a"
            floated="right"
            title="Download"
            href={item.file?.download}
            icon={<Icon className="circled" name={downloadSVG} size="16px" />}
          />
        </Card.Header>
        <Card.Meta>{item.file?.size ? pb(item.file.size) : ''}</Card.Meta>
        <Card.Description>{item.description}</Card.Description>
      </Card.Content>
    </Card>
  );
};
