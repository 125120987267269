import downloadSVG from '@plone/volto/icons/download.svg';
import FooterBlockView from './FooterBlockView';
import FooterBlockEdit from './FooterBlockEdit';

export default (config) => {
  config.blocks.blocksConfig.siteFooter = {
    id: 'siteFooter',
    title: 'Site Footer',
    icon: downloadSVG,
    group: 'common',
    view: FooterBlockView,
    edit: FooterBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  return config;
};
