import React from 'react';
import superagent from 'superagent';

const useData = (url) => {
  const [loaded, setLoaded] = React.useState();
  const [loading, setLoading] = React.useState();

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;

    async function load() {
      let resp;

      if (mounted && url) {
        setLoading(true);
        setLoaded(false);
        resp = await superagent.get(url).accept('application/json');
      }

      if (mounted) {
        setLoading(false);
        setLoaded(true);
        setData(resp.body);
      }
    }

    load();

    return () => (mounted = false);
  }, [url]);

  return { loading, loaded, data };
};

export default useData;
