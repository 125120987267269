import { flattenToAppURL } from '@plone/volto/helpers';

import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

const FileCardSchema = (props) => {
  const { block, data = {}, onChangeBlock, openObjectBrowser } = props;

  return {
    title: 'File card',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href'],
      },
    ],
    properties: {
      href: {
        title: 'Source',
        icon: data.href ? clearSVG : navTreeSVG,
        iconAction: data.href
          ? () => {
              onChangeBlock(block, {
                ...data,
                href: '',
                title: '',
                description: '',
                preview_image: '',
              });
            }
          : () => openObjectBrowser({ mode: 'link' }),
        value: data.href && flattenToAppURL(data.href),
      },
    },
    required: ['field'],
  };
};

export default FileCardSchema;
