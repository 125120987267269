/**
 * View title block.
 * @module components/manage/Blocks/Title/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import SimplifiedBreadcrumb from './SimplifiedBreadcrumb';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { FormattedDate } from 'volto-trc-project/components';
// import moment from 'moment';

const Byline = ({ properties }) => {
  // TODO: add By @author \n <Facility name> <City> <State>
  // const author = properties.facility_name || 'no facility';
  const date = properties.effective || properties.modified;
  const {
    story_author = 'No author',
    facility_name = 'No facility',
    city_state = '',
  } = properties;

  return (
    <div className="story-byline">
      By <a href="/">{story_author}</a>
      {' \u2022 '}
      <FormattedDate isoDate={date} format="long" />
      <br />
      {facility_name && city_state
        ? `${facility_name || ''} - ${city_state}`
        : ''}
    </div>
  );
};

const StoryTags = ({ properties, names }) => {
  const values = names.map((n) => [n, properties[n]]).filter((nv) => !!nv[1]);

  return values.length ? (
    <Container className="tags">
      {values.map(([name, value], i) =>
        Array.isArray(value) ? (
          value.map((v, index) => (
            <Link
              className="ui label"
              to={`/stories?${name}=${v}`}
              key={`${v}-${index}`}
            >
              {v}
            </Link>
          ))
        ) : (
          <Link
            className="ui label"
            to={`/search?${name}=${value}`}
            key={`${value}-${i}`}
          >
            {value}
          </Link>
        ),
      )}
    </Container>
  ) : null;
};

const StoryTitleView = (props) => {
  const { properties = {} } = props;

  //  full-width large

  return (
    <>
      <div className="story-title">
        <SimplifiedBreadcrumb />
        <h1 className="documentFirstHeading">{properties.title}</h1>
        <div className="story-tags">
          <StoryTags {...props} names={['facility_type', 'us_state']} />
        </div>
        <Byline {...props} />
      </div>
    </>
  );
};

const TitleView = (props) => {
  const { properties, path = '' } = props;
  const type = properties['@type'];

  return type === 'story' ? (
    <StoryTitleView {...props} />
  ) : (
    <div className="heading-wrapper">
      {['NewsItem', 'Event'].includes(type) && <SimplifiedBreadcrumb />}
      {path.startsWith('/resources/') && <SimplifiedBreadcrumb />}
      <h1 className="documentFirstHeading">{properties.title}</h1>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TitleView.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TitleView;
